import { createAsyncThunk } from "@reduxjs/toolkit"
import * as api from "./api"
import { CreateCanteen, Canteen } from "types/Canteen"

import { FetchRequest, FetchAllResponse } from "types/common/FetchRequest"
import handleAPIRequestError, { handleNotificationError } from "utils/handleAPIRequestError"

import { showNotification } from "store/app/slice"

export const fetchCanteens = createAsyncThunk(
  "canteens/fetchCanteens",
  async ({ currentPage, rowsPerPage, keyword }: FetchRequest) => {
    const response = await api.fetchCanteens(currentPage, rowsPerPage, keyword)
    return response.data as FetchAllResponse<Canteen>
  }
)

export const fetchAvailableManagers = createAsyncThunk(
  "canteens/fetchAvailableManagers",
  async () => {
    const response = await api.fetchAvailableManagers()
    return response.data
  }
)

export const fetchCanteenById = createAsyncThunk(
  "canteens/fetchCanteenById",
  async (id: string): Promise<Canteen> => {
    const response = await api.fetchCanteenById(id)
    return response.data
  }
)

export const createCanteen = createAsyncThunk(
  "canteens/createCanteen",
  async (canteen: CreateCanteen, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.createCanteen(canteen)

      dispatch(showNotification({ status: "success", message: "Valgykla sukurta" }))
      return {
        ...canteen,
        id: response.data
      } as Canteen
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, "Nepavyko sukurti valgyklos")
        })
      )
      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)

export const updateCanteen = createAsyncThunk(
  "canteens/updateCanteen",
  async (canteen: Canteen, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.updateCanteen(canteen)

      dispatch(showNotification({ status: "success", message: "Valgykla atnaujinta" }))

      return {
        ...canteen,
        id: response.data
      } as Canteen
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, "Nepavyko atnaujinti valgyklos")
        })
      )
      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)

export const deleteCanteen = createAsyncThunk(
  "canteens/deleteCanteen",
  async (id: string, { dispatch }) => {
    try {
      const response = await api.deleteCanteen(id)

      dispatch(showNotification({ status: "success", message: "Valgykla ištrinta" }))
      return response.data
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, "Nepavyko ištrinti valgyklos")
        })
      )
    }
  }
)
