import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import initialState, { RequestStore } from "./state"
import * as thunkActions from "./apiActions"
import { APIRequestError } from "types/common/ApiRequestError"

export const requestSlice = createSlice({
  name: "requests",
  initialState,
  reducers: {
    setCurrentPage: (state: RequestStore, action: PayloadAction<number>) => {
      state.pagination.page = action.payload
    },
    setRowsPerPage: (state: RequestStore, action: PayloadAction<number>) => {
      state.pagination.rowsPerPage = action.payload
      state.pagination.page = 1
    }
  },
  extraReducers: (builder) => {
    builder
      // Fetch all
      .addCase(thunkActions.fetchRequests.pending, (state) => {
        state.loading = "pending"
      })
      .addCase(thunkActions.fetchRequests.fulfilled, (state, action) => {
        const { data, totalCount } = action.payload
        state.items = data
        state.pagination = {
          ...state.pagination,
          totalCount
        }
        state.loading = "idle"
      })
      // Fetch by id
      .addCase(thunkActions.fetchRequestById.pending, (state) => {
        state.loading = "pending"
      })
      .addCase(thunkActions.fetchRequestById.fulfilled, (state) => {
        state.loading = "idle"
      })
      // Create request
      .addCase(thunkActions.createRequest.pending, (state) => {
        state.loading = "pending"
      })
      .addCase(thunkActions.createRequest.rejected, (state, action) => {
        const { errors, status, title } = action.payload as APIRequestError
        state.loading = "failed"
        state.error = {
          errors,
          status,
          title
        }
      })
      .addCase(thunkActions.createRequest.fulfilled, (state, action) => {
        state.items.push(action.payload)
        state.loading = "succeeded"
        state.error = {
          errors: {},
          status: 200,
          title: ""
        }
      })
      // Update request
      .addCase(thunkActions.updateRequest.pending, (state) => {
        state.loading = "pending"
      })
      .addCase(thunkActions.updateRequest.fulfilled, (state, action) => {
        const index = state.items.findIndex((item) => item.id === action.payload.id)
        state.items[index] = action.payload
        state.loading = "succeeded"
        state.error = {
          errors: {},
          status: 200,
          title: ""
        }
      })
      .addCase(thunkActions.updateRequest.rejected, (state, action) => {
        const { errors, status, title } = action.payload as APIRequestError
        state.loading = "failed"
        state.error = {
          errors,
          status,
          title
        }
      })
      // Delete request
      .addCase(thunkActions.deleteRequest.pending, (state) => {
        state.loading = "pending"
      })
      .addCase(thunkActions.deleteRequest.fulfilled, (state, action) => {
        state.items = state.items.filter((item) => item.id !== action.payload)
        state.loading = "idle"
        state.pagination.totalCount -= 1
      })
  }
})

export const { setCurrentPage, setRowsPerPage } = requestSlice.actions
export default requestSlice.reducer
