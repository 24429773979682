import { lazy } from "react"

import { PrivateRoute } from "types/common/Route"

import { IoPeopleOutline } from "react-icons/io5"

const routes: PrivateRoute[] = [
  {
    path: "/users",
    name: "users",
    title: "Vartotojai",
    component: lazy(() => import("./pages/ListUsers")),
    visible: true,
    icon: IoPeopleOutline,
    canAccess: {
      roles: ["Users"],
      permissions: ["View"]
    },
    group: "settings"
  },
  {
    path: "/users/create",
    name: "create-user",
    title: "Sukurti vartotoją",
    component: lazy(() => import("./pages/CreateOrUpdateUser")),
    visible: false,
    canAccess: {
      roles: ["Users"],
      permissions: ["View", "Create"]
    },
    group: "settings"
  },
  {
    path: "/users/update/:id",
    name: "update-user",
    title: "Atnaujinti vartotoją",
    component: lazy(() => import("./pages/CreateOrUpdateUser")),
    visible: false,
    canAccess: {
      roles: ["Users"],
      permissions: ["View", "Update"]
    },
    group: "settings"
  }
]

export default routes
