import { FC, useEffect } from "react"

import { useSelector, useDispatch } from "react-redux"

import toast, { Toaster } from "react-hot-toast"
import { selectNotification } from "store/app/selector"
import { clearNotification } from "store/app/slice"

const Notifications: FC = () => {
  const dispatch = useDispatch()
  const notification = useSelector(selectNotification)

  useEffect(() => {
    if (notification && notification.status !== "") {
      displayNotification()
    }

    // eslint-disable-next-line
  }, [notification])

  const displayNotification = () => {
    if (notification.status === "success") {
      toast.success(notification.message)
    }
    if (notification.status === "error") {
      toast.error(notification.message, {
        duration: 12000
      })
    }

    dispatch(clearNotification())
  }
  return (
    <>
      <Toaster position='top-right' />
    </>
  )
}

export default Notifications
