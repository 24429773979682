import { FC, useMemo } from "react"

import { useSelector } from "react-redux"
import { selectSidebarExpanded, selectUserPermissions } from "store/app/selector"

import routes from "router/routes"

import List from "@mui/material/List"
import MainNavigationItem from "./MainNavigationItem"
import MainNavigationBrand from "./MainNavigationBrand"
import MainNavigationSettingsGroup from "./MainNavigationSettingsGroup"

const MainNavigation: FC = () => {
  const userPermissions = useSelector(selectUserPermissions)
  const expanded = useSelector(selectSidebarExpanded)

  const availablePrivateRoutes = useMemo(() => {
    return routes.private
      .filter((route) => {
        // Required roles & permissions
        const { roles, permissions } = route.canAccess

        if (roles.length === 0 && permissions.length === 0) {
          return true
        }

        let hasAccess = false
        userPermissions.forEach((permission) => {
          const s = permission.split(".")
          const aRole = s[0]
          const aPermission = s[1]

          if (roles.includes(aRole)) {
            if (aPermission === "All") {
              hasAccess = true
            } else if (permissions.includes(aPermission)) {
              hasAccess = true
            }
          }
        })

        return hasAccess
      })
      .filter((route) => route.visible)
  }, [userPermissions])

  return (
    <aside
      className={[`h-screen bg-white shadow-lg shadow-gray-300/5`, expanded ? "w-44" : "w-14"].join(
        " "
      )}
    >
      <MainNavigationBrand expanded={expanded} />
      <List component='nav' dense>
        {availablePrivateRoutes
          .filter((route) => !route.group)
          .map((route) => (
            <MainNavigationItem key={route.name} route={route} expanded={expanded} />
          ))}

        {userPermissions.includes("LogisticsManagers.All") ? (
          <>
            <MainNavigationSettingsGroup
              routes={availablePrivateRoutes.filter((route) => route.group === "settings")}
              expanded={expanded}
            />
          </>
        ) : null}
      </List>
    </aside>
  )
}

export default MainNavigation
