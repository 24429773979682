import { lazy } from "react"

import { PrivateRoute } from "types/common/Route"

import { IoReaderOutline } from "react-icons/io5"

const routes: PrivateRoute[] = [
  {
    path: "/requests",
    name: "request-list",
    title: "Užklausos",
    component: lazy(() => import("./pages/List")),
    visible: true,
    icon: IoReaderOutline,
    canAccess: {
      roles: ["LogisticsManagers"],
      permissions: ["View"]
    }
  },
  {
    path: "/requests/create",
    name: "create-request",
    title: "Sukurti užklausą",
    component: lazy(() => import("./pages/Create")),
    visible: false,
    canAccess: {
      roles: ["LogisticsManagers"],
      permissions: ["View", "Create"]
    }
  },
  {
    path: "/requests/update/:id",
    name: "update-request",
    title: "Atnaujinti užklausą",
    component: lazy(() => import("./pages/Update")),
    visible: false,
    canAccess: {
      roles: ["LogisticsManagers"],
      permissions: ["View", "Update"]
    }
  },
  {
    path: "/requests/offer/:id",
    name: "request-offer",
    title: "Užklausos pasiūlymas",
    component: lazy(() => import("./pages/ViewOffer")),
    visible: false,
    canAccess: {
      roles: ["LogisticsManagers"],
      permissions: ["View"]
    }
  }
]

export default routes
