import { createAsyncThunk } from "@reduxjs/toolkit"
import * as api from "./api"

import { FetchAllResponse, OrderFetchRequest } from "types/common/FetchRequest"
import {
  Order,
  OrderStatus,
  PlaceOrder,
  UpdateLineItemStatus,
  UpdateOrderStatus,
  UpdateSupplierDeliveryStatus,
  GroupBy,
  OrderLineItem,
  UpdateLineItemQuantity,
  DeleteLineItem,
  AddLineItems,
  SubmitAllItemsAs,
  UpdateNotesBySupplier,
  UpdateOrderDeliveryNotes
} from "types/Order"
import handleAPIRequestError, { handleNotificationError } from "utils/handleAPIRequestError"
import { showNotification } from "store/app/slice"

export const fetchOrders = createAsyncThunk(
  "orders/fetchOrders",
  async ({ currentPage, rowsPerPage, status, canteenId, supplierId }: OrderFetchRequest) => {
    const response = await api.fetchOrders(currentPage, rowsPerPage, status, canteenId, supplierId)
    return response.data as FetchAllResponse<Order>
  }
)

export const fetchOrdersGroups = createAsyncThunk(
  "orders/fetchOrdersGroups",
  async (group: GroupBy) => {
    const response = await api.fetchOrdersGroups(group)
    return response.data
  }
)

export const fetchOrder = createAsyncThunk("orders/fetchOrder", async (id: string) => {
  const response = await api.fetchOrder(id)
  return response.data as Order
})

export const placeOrder = createAsyncThunk(
  "orders/placeOrder",
  async (order: PlaceOrder, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.placeOrder(order)
      dispatch(showNotification({ status: "success", message: "Užsakymas pateiktas" }))
      return response.data as string
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, "Užsakymo nepavyko pateikti")
        })
      )
      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)

export const updateOrderStatus = createAsyncThunk(
  "orders/updateOrderStatus",
  async (data: UpdateOrderStatus, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.updateStatus(data)
      dispatch(showNotification({ status: "success", message: "Užsakymas atnaujintas" }))
      return response.data as OrderStatus
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, "Užsakymo nepavyko atnaujinti")
        })
      )
      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)

export const updateOrderDeliveryNotes = createAsyncThunk(
  "orders/updateOrderDeliveryNotes",
  async (data: UpdateOrderDeliveryNotes, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.updateOrderDeliveryNotes(data)
      dispatch(showNotification({ status: "success", message: "Komentarai išsaugoti" }))
      return response.data as OrderStatus
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, "Nepavyko išsaugoti. Bandykite vėl")
        })
      )
      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)

export const updateOrderDate = createAsyncThunk(
  "orders/updateOrderDate",
  async (
    data: {
      orderId: string
      deliveryDate: string
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await api.updateOrderDate(data)
      dispatch(showNotification({ status: "success", message: "Užsakymo data atnaujinta" }))
      return response.data as string
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(
            error.response.data,
            "Užsakymo datos nepavyko atnaujinti"
          )
        })
      )
      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)

export const updateLineItemStatus = createAsyncThunk(
  "orders/updateLineItemStatus",
  async (data: UpdateLineItemStatus, { dispatch, rejectWithValue }) => {
    try {
      await api.updateLineItemStatus(data)
      dispatch(
        showNotification({ status: "success", message: "Užsakymas produkto statusas atnaujintas" })
      )
      return data as UpdateLineItemStatus
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(
            error.response.data,
            "Užsakymo produkto statuso nepavyko atnaujinti"
          )
        })
      )
      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)

interface ExtendedUpdateSupplierDeliveryStatus extends UpdateSupplierDeliveryStatus {
  lineItems: OrderLineItem[]
}
interface UpdateSupplierDeliveryStatusResponse extends ExtendedUpdateSupplierDeliveryStatus {
  orderStatus: OrderStatus
}

export const updateSupplierDeliveryStatus = createAsyncThunk(
  "orders/updateSupplierDeliveryStatus",
  async (data: ExtendedUpdateSupplierDeliveryStatus, { dispatch, rejectWithValue }) => {
    try {
      const obj = { ...data }
      // @ts-ignore
      delete obj.lineItems
      const response = await api.updateSupplierDeliveryStatus(obj)

      const orderStatus = response.data
      dispatch(showNotification({ status: "success", message: "Tiekėjo prekės pristatytos" }))
      return { ...data, orderStatus } as UpdateSupplierDeliveryStatusResponse
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(
            error.response.data,
            "Nepavyko pakeisti tiekėjo pristatymo statuso"
          )
        })
      )
      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)

export const updateLineItemQuantity = createAsyncThunk(
  "orders/updateLineItemQuantity",
  async (data: UpdateLineItemQuantity, { dispatch, rejectWithValue }) => {
    try {
      await api.updateSupplierDeliveryQuantity(data)
      dispatch(showNotification({ status: "success", message: "Produkto kiekis atnaujintas" }))
      return data as UpdateLineItemQuantity
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(
            error.response.data,
            "Nepavyko atnaujinti produkto kiekio"
          )
        })
      )
      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)

export const addLineItems = createAsyncThunk(
  "orders/addLineItems",
  async (data: AddLineItems, { dispatch, rejectWithValue }) => {
    try {
      const obj = {
        ...data,
        lineItems: data.lineItems.map((x) => ({
          catalogItemId: x.id,
          name: x.name,
          quantity: x.quantity,
          deliveryStatus: 0 as 0
        }))
      }
      const response = await api.addLineItems(obj)
      dispatch(showNotification({ status: "success", message: "Pridėtos prekės prie užsakymo" }))

      return {
        ...data,
        lineItems: data.lineItems.map((x) => {
          const item = response.data.find((y: OrderLineItem) => y.productId === x.id)

          return item
            ? {
                ...x,
                id: item.id
              }
            : x
        })
      } as AddLineItems
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(
            error.response.data,
            "Nepavyko pridėti prekių prie užsakymo"
          )
        })
      )
      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)

export const submitAllItemsAs = createAsyncThunk(
  "orders/submitAllItemsAs",
  async (data: SubmitAllItemsAs, { dispatch, rejectWithValue }) => {
    try {
      await api.submitAllItemsAs(data)
      dispatch(showNotification({ status: "success", message: "Prekių statusas pakeistas" }))
      return data as SubmitAllItemsAs
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, "Nepavyko pakeisti prekių statuso")
        })
      )
      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)

export const deleteLineItem = createAsyncThunk(
  "orders/deleteLineItem",
  async (data: DeleteLineItem, { dispatch, rejectWithValue }) => {
    try {
      await api.deleteLineItem(data)
      dispatch(showNotification({ status: "success", message: "Ištrintas užsakymo produktas" }))
      return data as DeleteLineItem
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(
            error.response.data,
            "Nepavyko ištrinti užsakymo produkto"
          )
        })
      )
      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)

export const updateDeliveryNotesBySupplier = createAsyncThunk(
  "orders/updateDeliveryNotesBySupplier",
  async (data: UpdateNotesBySupplier, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.updateDeliveryNotesBySupplier(data)
      dispatch(showNotification({ status: "success", message: "Komentaras atnaujintas" }))
      return response.data as Record<string, string>
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(
            error.response.data,
            "Nepavyko atnaujinti komentaro tiekėjui"
          )
        })
      )
      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)
