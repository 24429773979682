import { FC } from "react"
import { useDispatch } from "react-redux"
import { toggleSidebar } from "store/app/slice"

import ButtonBase from "@mui/material/ButtonBase"
import { IoMenuOutline } from "react-icons/io5"

const HeaderToggleButton: FC = () => {
  const dispatch = useDispatch()

  const handleSidebarToggle = () => {
    dispatch(toggleSidebar())
  }

  return (
    <ButtonBase
      centerRipple
      onClick={handleSidebarToggle}
      className='px-4 h-full cursor-pointer flex items-center justify-center'
    >
      <IoMenuOutline className='text-md' />
    </ButtonBase>
  )
}

export default HeaderToggleButton
