import { createAsyncThunk } from "@reduxjs/toolkit"
import { showNotification } from "store/app/slice"

import * as api from "./api"

import handleAPIRequestError, { handleNotificationError } from "utils/handleAPIRequestError"
import { CreateOrderTemplate, OrderTemplate, OrderTemplateFull } from "types/OrderTemplate"
import { FetchAllResponse, SearchRequest } from "types/common/FetchRequest"
import { CatalogItem } from "types/Product"

export const fetchAllOrderTemplates = createAsyncThunk(
  "orders/fetchAllOrderTemplates",
  async (canteenId: string | null, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.fetchAllOrderTemplates(canteenId)
      return response.data as FetchAllResponse<OrderTemplate>
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, "Nepavyko gauti užsakymo ruošinių")
        })
      )
      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)

export const fetchOrderTemplateById = createAsyncThunk(
  "orders/fetchOrderTemplateById",
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.fetchOrderTemplateById(id)
      return response.data as OrderTemplateFull
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, "Nepavyko gauti užsakymo ruošinių")
        })
      )
      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)

export const createOrderTemplate = createAsyncThunk(
  "orders/createOrderTemplate",
  async (data: CreateOrderTemplate, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.createOrderTemplate(data)
      dispatch(showNotification({ status: "success", message: "Užsakymo ruošinys sukurtas" }))
      return response.data as string
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(
            error.response.data,
            "Užsakymo datos nepavyko atnaujinti"
          )
        })
      )
      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)

export const deleteOrderTemplateById = createAsyncThunk(
  "orders/deleteOrderTemplateById",
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.deleteOrderTemplateById(id)
      dispatch(showNotification({ status: "success", message: "Užsakymo ruošinys sukurtas" }))
      return response.data as string
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(
            error.response.data,
            "Užsakymo datos nepavyko atnaujinti"
          )
        })
      )
      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)

export const fetchCatalogItems = createAsyncThunk(
  "marketplace/fetchCatalogItems",
  async ({ currentPage, rowsPerPage, keyword, categoryIds }: SearchRequest) => {
    const response = await api.fetchCatalogItems(currentPage, rowsPerPage, keyword, categoryIds)
    return response.data as FetchAllResponse<CatalogItem>
  }
)
