import { User, UserRole } from "types/User"
import { LoadingStatus } from "types/common/LoadingStatus"

import * as paginationConfig from "constants/pagination"
import { APIRequestError } from "types/common/ApiRequestError"
export interface UserStore {
  loading: LoadingStatus
  items: User[]
  availableRoles: UserRole[]
  pagination: paginationConfig.State
  error: APIRequestError

  user: User | null
  userRoles: UserRole[]
}

const initialState: UserStore = {
  loading: "idle",
  items: [],
  pagination: paginationConfig.state,
  availableRoles: [],
  error: {
    status: 0,
    title: "",
    errors: {}
  },
  user: null,
  userRoles: []
}

export default initialState
