import { lazy } from "react"

import { PrivateRoute } from "types/common/Route"

import { IoAlbumsOutline } from "react-icons/io5"

const routes: PrivateRoute[] = [
  {
    path: "/products",
    name: "product-list",
    title: "Produktai",
    component: lazy(() => import("./pages/List")),
    visible: true,
    icon: IoAlbumsOutline,
    canAccess: {
      roles: ["Products"],
      permissions: ["View"]
    },
    group: "settings"
  },
  {
    path: "/products/create",
    name: "create-product",
    title: "Sukurti produktą",
    component: lazy(() => import("./pages/Create")),
    visible: false,
    canAccess: {
      roles: ["Products"],
      permissions: ["View", "Create"]
    },
    group: "settings"
  },
  {
    path: "/products/update/:id",
    name: "update-product",
    title: "Atnaujinti produktą",
    component: lazy(() => import("./pages/Update")),
    visible: false,
    canAccess: {
      roles: ["Products"],
      permissions: ["View", "Update"]
    },
    group: "settings"
  }
]

export default routes
