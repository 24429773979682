import { lazy } from "react"

import { PrivateRoute } from "types/common/Route"

import { IoAlbumsOutline } from "react-icons/io5"

const routes: PrivateRoute[] = [
  {
    path: "/manage-catalog",
    name: "manage-catalog",
    title: "Produktų katalogas",
    icon: IoAlbumsOutline,
    component: lazy(() => import("./pages/ManageCatalog")),
    visible: true,
    canAccess: {
      roles: ["LogisticsManagers"],
      permissions: ["View"]
    }
  },
  {
    path: "/add-catalog-item",
    name: "add-catalog-item",
    title: "Pridėti produktą prie katalogo",
    component: lazy(() => import("./pages/AddCatalogItem")),
    visible: false,
    canAccess: {
      roles: ["LogisticsManagers"],
      permissions: ["View", "Create"]
    }
  },
  {
    path: "/update-catalog-item/:id",
    name: "update-catalog-item",
    title: "Atnaujinti produktą katalogo",
    component: lazy(() => import("./pages/UpdateCatalogItem")),
    visible: false,
    canAccess: {
      roles: ["LogisticsManagers"],
      permissions: ["View", "Update"]
    }
  }
]

export default routes
