import { createSlice } from "@reduxjs/toolkit"
import initialState from "./state"
import * as thunkActions from "./apiActions"
import { APIRequestError } from "types/common/ApiRequestError"

export const categoriesSlice = createSlice({
  name: "products",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch categories
      .addCase(thunkActions.fetchCategories.pending, (state) => {
        state.loading = "pending"
      })
      .addCase(thunkActions.fetchCategories.fulfilled, (state, action) => {
        state.items = action.payload.data.sort((a, b) => a.iconNumber - b.iconNumber)
        state.loading = "idle"
      })
      // Create product
      .addCase(thunkActions.createCategory.pending, (state) => {
        state.loading = "pending"
      })
      .addCase(thunkActions.createCategory.rejected, (state, action) => {
        const { errors, status, title } = action.payload as APIRequestError
        state.loading = "failed"

        state.error = {
          errors,
          status,
          title
        }
      })
      .addCase(thunkActions.createCategory.fulfilled, (state, action) => {
        const data = action.payload as any
        const items = [...state.items]
        items.forEach((item) => {
          // categories level
          if (item.id === data.categoryId) {
            item.subCategories.push(data)
          } else if (item.subCategories.length !== 0) {
            item.subCategories.forEach((sub) => {
              if (sub.id === data.categoryId) {
                sub.subCategories.push(data)
              }
            })
          }
        })

        state.items = [...items]

        state.loading = "succeeded"
        state.error = {
          errors: {},
          status: 200,
          title: ""
        }
      })
      // Update product
      .addCase(thunkActions.updateCategory.pending, (state) => {
        state.loading = "pending"
      })
      .addCase(thunkActions.updateCategory.rejected, (state, action) => {
        const { errors, status, title } = action.payload as APIRequestError
        state.loading = "failed"

        state.error = {
          errors,
          status,
          title
        }
      })
      .addCase(thunkActions.updateCategory.fulfilled, (state, action) => {
        const data = action.payload as any
        const items = [...state.items]
        items.forEach((item) => {
          // categories level
          if (item.id === data.categoryId) {
            item.subCategories.push(data)
          } else if (item.subCategories.length !== 0) {
            item.subCategories.forEach((sub) => {
              if (sub.id === data.categoryId) {
                sub.subCategories.push(data)
              }
            })
          }
        })

        state.items = [...items]

        state.loading = "succeeded"
        state.error = {
          errors: {},
          status: 200,
          title: ""
        }
      })
      // Update product
      .addCase(thunkActions.deleteCategory.pending, (state) => {
        state.loading = "pending"
      })
      .addCase(thunkActions.deleteCategory.rejected, (state, action) => {
        const { errors, status, title } = action.payload as APIRequestError
        state.loading = "failed"

        state.error = {
          errors,
          status,
          title
        }
      })
      .addCase(thunkActions.deleteCategory.fulfilled, (state, action) => {
        const deletedItemId = action.payload
        const items = [...state.items].map((item) => {
          if (item.subCategories && item.subCategories.length > 0) {
            let index = -1
            item.subCategories.map((x, xIndex) => {
              if (x.id === deletedItemId) {
                index = xIndex
              } else {
                index = x.subCategories.findIndex((y) => y.id === deletedItemId)

                if (index !== -1) {
                  x.subCategories.splice(index, 1)
                  index = -1
                }
              }

              return x
            })

            if (index !== -1) {
              item.subCategories.splice(index, 1)
            }
          }

          return item
        })

        state.items = [...items]

        state.loading = "succeeded"
        state.error = {
          errors: {},
          status: 200,
          title: ""
        }
      })
  }
})

// export const {} = categoriesSlice.actions
export default categoriesSlice.reducer
