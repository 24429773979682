import { createAsyncThunk } from "@reduxjs/toolkit"
import { Category, CreateCategory, UpdateCategory } from "types/Category"
import * as api from "./api"

import { FetchAllResponse } from "types/common/FetchRequest"
import handleAPIRequestError, { handleNotificationError } from "utils/handleAPIRequestError"
import { showNotification } from "store/app/slice"

export const fetchCategories = createAsyncThunk("categories/fetchCategories", async () => {
  const response = await api.fetchCategories()

  return response.data as FetchAllResponse<Category>
})

export const createCategory = createAsyncThunk(
  "categories/createSubCategory",
  async (data: CreateCategory, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.createCategory(data)

      dispatch(showNotification({ status: "success", message: "Kategorija sukurta" }))
      return {
        ...data,
        id: response.data,
        subCategories: []
      } as Category
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, "Kategorijos nepavyko sukurti")
        })
      )
      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)
export const updateCategory = createAsyncThunk(
  "categories/updateCategory",
  async (data: UpdateCategory, { dispatch, rejectWithValue }) => {
    try {
      await api.updateCategory(data)

      dispatch(showNotification({ status: "success", message: "Kategorija atnaujinta" }))
      return data as UpdateCategory
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, "Kategorijos nepavyko atnaujinti")
        })
      )
      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)
export const deleteCategory = createAsyncThunk(
  "categories/deleteCategory",
  async (id: string, { dispatch }) => {
    try {
      const response = await api.deleteCategory(id)
      dispatch(showNotification({ status: "success", message: "Kategorija ištrinti" }))
      return response.data as string
    } catch (error: any) {
      let message = ""
      if (error.response.data.status === 409) {
        message = "Kategorija priskirta produktui"
      } else {
        message = "Kategorijos nepavyko ištrinti"
      }
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, message)
        })
      )
    }
  }
)
