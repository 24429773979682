import { Route, PrivateRoute } from "types/common/Route"

// Public routes
import authRoutes from "pages/auth/routes"
import requestOfferRoutes from "pages/request-offers/routes"

// Private routes
import dashboardRoutes from "pages/dashboard/routes"
import usersRoutes from "pages/users/routes"
import supplierRoutes from "pages/suppliers/routes"
import canteenRoutes from "pages/canteens/routes"
import productRoutes from "pages/products/routes"
import categoryRoutes from "pages/categories/routes"
import requestRoutes from "pages/requests/routes"
import catalogItemRoutes from "pages/catalog-items/routes"
import orderRoutes from "pages/orders/routes"
import marketplaceRoutes from "pages/marketplace/routes"
import profileRoutes from "pages/profile/routes"

import errorRoutes from "pages/errors/routes"

interface AppRoutes {
  public: Route[]
  private: PrivateRoute[]
}

const routes: AppRoutes = {
  public: [...authRoutes, ...requestOfferRoutes],
  private: [
    ...dashboardRoutes,
    ...profileRoutes,
    ...usersRoutes,
    ...supplierRoutes,
    ...canteenRoutes,
    ...categoryRoutes,
    ...productRoutes,
    ...requestRoutes,
    ...catalogItemRoutes,
    ...marketplaceRoutes,
    ...orderRoutes,
    ...errorRoutes
  ]
}

export default routes
