import { lazy } from "react"

import { PrivateRoute } from "types/common/Route"

import { IoHomeOutline } from "react-icons/io5"

const routes: PrivateRoute[] = [
  {
    path: "/",
    name: "dashboard",
    title: "Pagrindinis",
    component: lazy(() => import("./Dashboard")),
    visible: true,
    icon: IoHomeOutline,
    canAccess: {
      roles: [],
      permissions: []
    }
  }
]

export default routes
