import { configureStore, combineReducers } from "@reduxjs/toolkit"
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from "redux-persist"

// Application reducers
import appReducer from "./app/slice"
import authReducer from "pages/auth/store/slice"
import dashboardReducer from "pages/dashboard/store/slice"
import usersReducer from "pages/users/store/slice"
import suppliersReducer from "pages/suppliers/store/slice"
import canteensReducer from "pages/canteens/store/slice"
import categoriesReducer from "pages/categories/store/slice"
import productsReducer from "pages/products/store/slice"
import requestsReducer from "pages/requests/store/slice"
import requestOffersReducer from "pages/request-offers/store/slice"
import ordersReducer from "pages/orders/store/slice"
import catalogItemsReducer from "pages/catalog-items/store/slice"
import martketplaceReducer from "pages/marketplace/store/slice"

// Persist configurations
import * as persistConfigs from "./persistConfigs"

const rootReducer = combineReducers({
  app: appReducer,
  auth: persistReducer(persistConfigs.auth, authReducer),
  dashboard: dashboardReducer,
  users: usersReducer,
  suppliers: suppliersReducer,
  canteens: canteensReducer,
  orders: persistReducer(persistConfigs.orders, ordersReducer),
  products: productsReducer,
  categories: categoriesReducer,
  requests: requestsReducer,
  requestOffers: requestOffersReducer,
  catalogItems: catalogItemsReducer,
  marketplace: persistReducer(persistConfigs.marketplace, martketplaceReducer)
})

const persistedReducer = persistReducer(persistConfigs.root, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
