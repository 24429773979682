import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import initialState from "./state"

import { fetchPersonalProfile, fetchProfilePermissions, updateUserProfile } from "./apiActions"
import { User } from "types/User"
import { APIRequestError } from "types/common/ApiRequestError"

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.sidebar.expanded = !state.sidebar.expanded
    },

    showNotification: (
      state,
      action: PayloadAction<{ status: "success" | "error" | ""; message: string }>
    ) => {
      state.notification = action.payload
    },

    clearNotification: (state) => {
      state.notification = {
        status: "",
        message: ""
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPersonalProfile.fulfilled, (state, action) => {
      state.loading = true
      state.profile = action.payload
    })
    builder.addCase(fetchProfilePermissions.fulfilled, (state, action) => {
      state.permissions = action.payload.map((p: string) => p.replace("Permissions.", ""))
      state.loading = false
    })
    builder.addCase(updateUserProfile.pending, (state) => {
      state.loading = true
    })
    builder.addCase(updateUserProfile.fulfilled, (state, action) => {
      const { ...profile } = action.payload
      const updatedProfile = { ...state.profile, ...profile } as User
      state.profile = updatedProfile
      state.loading = false
    })
    builder.addCase(updateUserProfile.rejected, (state, action) => {
      const { errors, status, title } = action.payload as APIRequestError
      state.loading = false
      state.error = {
        errors,
        status,
        title
      }
    })
  }
})

export const { toggleSidebar, showNotification, clearNotification } = appSlice.actions
export default appSlice.reducer
