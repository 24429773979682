import { Supplier } from "types/Supplier"
import { ManagingUser } from "types/User"
import { APIRequestError } from "types/common/ApiRequestError"
import { LoadingStatus } from "types/common/LoadingStatus"

import * as paginationConfig from "constants/pagination"

export interface SupplierStore {
  loading: LoadingStatus
  items: Supplier[]
  availableManagers: ManagingUser[]
  pagination: paginationConfig.State
  error: APIRequestError
}

const initialState: SupplierStore = {
  items: [],
  availableManagers: [],
  pagination: paginationConfig.state,
  loading: "idle",
  error: {
    status: 0,
    title: "",
    errors: {}
  }
}

export default initialState
