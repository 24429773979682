import { createAsyncThunk } from "@reduxjs/toolkit"
import { showNotification } from "store/app/slice"
import { FetchAllResponse, SearchRequest } from "types/common/FetchRequest"
import { CatalogItem, CreateCatalogItem } from "types/Product"
import handleAPIRequestError, { handleNotificationError } from "utils/handleAPIRequestError"
import * as api from "./api"

export const fetchCatalogItems = createAsyncThunk(
  "catalogItems/fetchCatalogItems",
  async ({ currentPage, rowsPerPage, keyword, categoryIds }: SearchRequest) => {
    const response = await api.fetchCatalogItems(currentPage, rowsPerPage, keyword, categoryIds)
    return response.data as FetchAllResponse<CatalogItem>
  }
)

export const addCatalogItem = createAsyncThunk(
  "catalogItems/addCatalogItem",
  async (data: CreateCatalogItem, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.addCatalogItem(data)
      dispatch(showNotification({ status: "success", message: "Produktas pridėtas prie katalogo" }))
      return response.data as string
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, "Nepavyko pridėti produkto")
        })
      )
      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)

export const updateCatalogItem = createAsyncThunk(
  "catalogItems/updateCatalogItem",
  async (data: CatalogItem, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.updateCatalogItem(data)
      dispatch(showNotification({ status: "success", message: "Produktas atnaujintas" }))
      return response.data as string
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, "Nepavyko atnaujinti produkto")
        })
      )
      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)

export const deleteCatalogItem = createAsyncThunk(
  "catalogItems/deleteCatalogItem",
  async (id: string, { dispatch }) => {
    try {
      const response = await api.deleteCatalogItem(id)
      dispatch(showNotification({ status: "success", message: "Produktas ištrintas" }))
      return response.data as string
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, "Nepavyko ištrinti produkto")
        })
      )
    }
  }
)
