import http from "http/http"
import { CreateCatalogItem, CatalogItem } from "types/Product"

export const fetchCatalogItems = (
  currentPage: number,
  rowsPerPage: number,
  keyword: string = "",
  categoryIds: string[] = []
) =>
  http.post("/v1/catalogitems/search", {
    pageSize: rowsPerPage,
    pageNumber: currentPage,
    keyword,
    categoryIds
  })

export const addCatalogItem = (data: CreateCatalogItem) => http.post(`/v1/catalogitems`, data)

export const updateCatalogItem = (data: CatalogItem) =>
  http.put(`/v1/catalogitems/${data.id}`, data)

export const deleteCatalogItem = (id: string) => http.delete(`/v1/catalogitems/${id}`)
