export interface DashboardStore {
  statistics: {
    logistics: {
      supplierCount: number
      catalogItemCount: number
      canteenCount: number
      activeRequestCount: number
      activeOrderCount: number
    }
    canteen: {
      catalogItemCount: number
      activeOrderCount: number
    }
  }

  loading: "pending" | "idle" | "error"
}

const initialState: DashboardStore = {
  statistics: {
    logistics: {
      supplierCount: 0,
      catalogItemCount: 0,
      canteenCount: 0,
      activeRequestCount: 0,
      activeOrderCount: 0
    },
    canteen: {
      catalogItemCount: 0,
      activeOrderCount: 0
    }
  },

  loading: "idle"
}

export default initialState
