import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import initialState, { ProductStore } from "./state"
import * as thunkActions from "./apiActions"
import { APIRequestError } from "types/common/ApiRequestError"

export const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setCurrentPage: (state: ProductStore, action: PayloadAction<number>) => {
      state.pagination.page = action.payload
    },
    setRowsPerPage: (state: ProductStore, action: PayloadAction<number>) => {
      state.pagination.rowsPerPage = action.payload
      state.pagination.page = 1
    }
  },
  extraReducers: (builder) => {
    builder
      // Fetch all
      .addCase(thunkActions.fetchProducts.pending, (state) => {
        state.loading = "pending"
      })
      .addCase(thunkActions.fetchProducts.fulfilled, (state, action) => {
        const { data, totalCount } = action.payload
        state.items = data
        state.pagination = { ...state.pagination, totalCount }
        state.loading = "idle"
      })
      // Fetch by id
      .addCase(thunkActions.fetchProductById.pending, (state) => {
        state.loading = "pending"
      })
      .addCase(thunkActions.fetchProductById.fulfilled, (state) => {
        state.loading = "idle"
      })
      // Create product
      .addCase(thunkActions.createProduct.pending, (state) => {
        state.loading = "pending"
      })
      .addCase(thunkActions.createProduct.rejected, (state, action) => {
        const { errors, status, title } = action.payload as APIRequestError
        state.loading = "failed"

        state.error = {
          errors,
          status,
          title
        }
      })
      .addCase(thunkActions.createProduct.fulfilled, (state, action) => {
        state.items.push(action.payload)
        state.loading = "succeeded"
        state.error = {
          errors: {},
          status: 200,
          title: ""
        }
      })
      // Update product
      .addCase(thunkActions.updateProduct.pending, (state) => {
        state.loading = "pending"
      })
      .addCase(thunkActions.updateProduct.fulfilled, (state, action) => {
        const index = state.items.findIndex((item) => item.id === action.payload.id)
        state.items[index] = action.payload
        state.loading = "succeeded"
        state.error = {
          errors: {},
          status: 200,
          title: ""
        }
      })
      .addCase(thunkActions.updateProduct.rejected, (state, action) => {
        const { errors, status, title } = action.payload as APIRequestError
        state.loading = "failed"
        state.error = {
          errors,
          status,
          title
        }
      })
      // Delete product
      .addCase(thunkActions.deleteProduct.pending, (state) => {
        state.loading = "pending"
      })
      .addCase(thunkActions.deleteProduct.fulfilled, (state, action) => {
        state.items = state.items.filter((item) => item.id !== action.payload)
        state.loading = "idle"
        state.pagination.totalCount -= 1
      })
  }
})

export const { setCurrentPage, setRowsPerPage } = productSlice.actions
export default productSlice.reducer
