import { CatalogItem, ProductInCart } from "types/Product"
import * as paginationConfig from "constants/pagination"
import { OrderTemplate } from "types/OrderTemplate"

export interface MarketplaceStore {
  cart: ProductInCart[]
  copiedCart: ProductInCart[]
  orderTemplates: OrderTemplate[]
  marketplaceItems: CatalogItem[]
  filters: {
    category: null | string
    keyword: string
  }
  loading: "idle" | "pending" | "succeeded" | "failed"
  pagination: paginationConfig.State
}

const initialState: MarketplaceStore = {
  cart: [],
  copiedCart: [],

  orderTemplates: [],

  marketplaceItems: [],

  filters: {
    category: null,
    keyword: ""
  },

  loading: "idle",
  pagination: {
    ...paginationConfig.state,
    rowsPerPage: 30
  }
}

export default initialState
