import { createAsyncThunk } from "@reduxjs/toolkit"
import { CreateUser, UpdateUser, User } from "types/User"

import * as api from "./api"

import { FetchRequest, FetchAllResponse } from "types/common/FetchRequest"
import handleAPIRequestError, { handleNotificationError } from "utils/handleAPIRequestError"
import { showNotification } from "store/app/slice"

export const fetchUsers = createAsyncThunk(
  "users/fetchUsers",
  async ({ currentPage, rowsPerPage }: FetchRequest) => {
    const response = await api.fetchUsers(currentPage, rowsPerPage)
    return response.data as FetchAllResponse<User>
  }
)

export const fetchAvailableRoles = createAsyncThunk("users/fetchAvailableRoles", async () => {
  const response = await api.fetchAvailableRoles()
  return response.data
})

export const fetchUserById = createAsyncThunk("users/fetchUserById", async (id: string) => {
  const response = await api.fetchUserById(id)
  return response.data
})

export const createUser = createAsyncThunk(
  "users/createUser",
  async (user: CreateUser, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.createUser({
        ...user,
        confirmPassword: user.password
      })

      dispatch(showNotification({ status: "success", message: "Vartotojas sukurtas" }))

      return response.data.user as User
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, "Vartotojo nepavyko sukurti")
        })
      )

      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)

export const updateUser = createAsyncThunk(
  "users/updateUser",
  async (user: UpdateUser, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.updateUser({
        ...user
        // confirmPassword: user.password
      })

      dispatch(showNotification({ status: "success", message: "Vartotojas atnaujintas" }))

      return response.data.user as User
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, "Vartotojo nepavyko atnaujinti")
        })
      )

      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)

export const createSupplierUser = createAsyncThunk(
  "users/createSupplierUser",
  async (user: CreateUser, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.createSupplierUser({
        ...user
      })

      dispatch(showNotification({ status: "success", message: "Vartotojas sukurtas" }))

      return response.data.user as User
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, "Vartotojo nepavyko sukurti")
        })
      )

      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)

export const deleteUser = createAsyncThunk("users/deleteUser", async (id: string, { dispatch }) => {
  try {
    const response = await api.deleteUser(id)
    dispatch(showNotification({ status: "success", message: "Vartotojas ištrintas" }))

    return response.data
  } catch (error: any) {
    dispatch(
      showNotification({
        status: "error",
        message: handleNotificationError(error.response.data, "Vartotojo nepavyko ištrinti")
      })
    )
  }
})
export const updateUserRole = createAsyncThunk(
  "users/updateUserRole",
  async (values: UpdateUser, { dispatch }) => {
    try {
      const response = await api.updateUserRole(values)
      dispatch(showNotification({ status: "success", message: "Vartotojo rolės atnaujintos" }))

      return response.data
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, "Vartotojo nepavyko atnaujinti")
        })
      )
    }
  }
)
