import { FC, ReactNode } from "react"
import { setDefaultOptions } from "date-fns"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers"

import { lt } from "date-fns/locale"

setDefaultOptions({ locale: lt })

interface Props {
  children: ReactNode
}

const LocalizationAdapter: FC<Props> = ({ children }) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>{children}</LocalizationProvider>
)

export default LocalizationAdapter
