import { createSlice } from "@reduxjs/toolkit"
import initialState from "./state"
import * as thunkActions from "./apiActions"

export const dashboardSlice = createSlice({
  name: "requestOffers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Canteen
      .addCase(thunkActions.fetchCanteenStatistics.pending, (state) => {
        state.loading = "pending"
      })

      .addCase(thunkActions.fetchCanteenStatistics.fulfilled, (state, action) => {
        state.loading = "idle"
        state.statistics.canteen = { ...action.payload }
      })
      // Logistics
      .addCase(thunkActions.fetchLogisticsStatistics.pending, (state) => {
        state.loading = "pending"
      })
      .addCase(thunkActions.fetchLogisticsStatistics.fulfilled, (state, action) => {
        state.statistics.logistics = { ...action.payload }
        state.loading = "idle"
      })
  }
})

export default dashboardSlice.reducer
