import { User } from "types/User"
import { APIRequestError } from "types/common/ApiRequestError"
export interface AppStore {
  profile: User | null
  permissions: string[]

  sidebar: {
    expanded: boolean
  }
  loading: boolean
  error: APIRequestError

  notification: {
    status: "success" | "error" | ""
    message: string
  }
}

const initialState: AppStore = {
  profile: null,
  permissions: [],

  sidebar: {
    expanded: true
  },
  loading: true,
  error: {
    status: 0,
    title: "",
    errors: {}
  },

  notification: {
    status: "",
    message: ""
  }
}

export default initialState
