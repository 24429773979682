import { createAsyncThunk } from "@reduxjs/toolkit"
import { showNotification } from "store/app/slice"
import { handleNotificationError } from "utils/handleAPIRequestError"
import * as api from "./api"

export const fetchLogisticsStatistics = createAsyncThunk(
  "dashboard/fetchLogisticsStatistics",
  async (_, { dispatch }) => {
    try {
      const response = await api.fetchLogisticsStatistics()
      return response.data
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, "Nepavyko gauti statistikos")
        })
      )
    }
  }
)

export const fetchCanteenStatistics = createAsyncThunk(
  "dashboard/fetchCanteenStatistics",
  async (_, { dispatch }) => {
    try {
      const response = await api.fetchCanteenStatistics()
      return response.data
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, "Nepavyko gauti statistikos")
        })
      )
    }
  }
)
