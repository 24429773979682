import { FC, useState, useRef, useEffect, SyntheticEvent } from "react"

import { useSelector, useDispatch } from "react-redux"
import { selectProfileName, selectProfileImage } from "store/app/selector"
import { logout } from "pages/auth/store/slice"

import ButtonBase from "@mui/material/ButtonBase"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

import HeaderMenu from "./HeaderMenu"
import HeaderToggleButton from "./HeaderToggleButton"
import { useNavigate } from "react-router-dom"

interface Props {}

const Header: FC<Props> = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)

  const userName = useSelector(selectProfileName)
  const imageUrl = useSelector(selectProfileImage)

  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus()
    }

    prevOpen.current = open
  }, [open])

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleNavigate = (event: Event | SyntheticEvent, to: string) => {
    setOpen(false)
    navigate(to)
  }

  const handleClose = (event?: Event | SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event?.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  const handleLogout = () => {
    setOpen(false)
    dispatch(logout())
  }

  return (
    <header className='min-h-12 sticky top-0 flex h-12 max-h-12 items-center justify-between bg-white pr-4  shadow-lg shadow-gray-300/5'>
      <HeaderToggleButton />

      <div className='flex h-full items-center rounded-md text-right text-xs'>
        {/* <HeaderNotifications /> */}
        <ButtonBase
          ref={anchorRef}
          onClick={handleToggle}
          disableRipple
          className='flex h-full items-center px-2'
        >
          <div className='mr-2 inline-flex h-7 w-7 items-center justify-center overflow-hidden rounded-full bg-amber-200'>
            {imageUrl && (
              <img
                src={imageUrl}
                alt='user avatar'
                className='h-full w-full object-cover object-center'
              />
            )}
          </div>
          <strong className='mx-1 block text-xs normal-case'>{userName}</strong>
          <div className='text-md h-[14px] text-primary'>
            {open ? <ExpandLessIcon fontSize='inherit' /> : <ExpandMoreIcon fontSize='inherit' />}
          </div>
        </ButtonBase>
        {open && (
          <HeaderMenu
            anchorRef={anchorRef}
            open={open}
            logout={handleLogout}
            navigate={handleNavigate}
            close={handleClose}
          />
        )}
      </div>
    </header>
  )
}

export default Header
