import { CatalogItem } from "types/Product"
import * as paginationConfig from "constants/pagination"
import { APIRequestError } from "types/common/ApiRequestError"

export interface CatalogItemsStore {
  items: CatalogItem[]
  entity: CatalogItem | null
  loading: "idle" | "pending" | "succeeded" | "failed"
  pagination: paginationConfig.State

  error: APIRequestError
}

const initialState: CatalogItemsStore = {
  items: [],

  entity: null,

  loading: "idle",
  pagination: paginationConfig.state,

  error: {
    status: 0,
    title: "",
    errors: {}
  }
}

export default initialState
