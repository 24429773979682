import { ReactNode, FC } from "react"
import { StyledEngineProvider } from "@mui/material/styles"

interface Props {
  children: ReactNode
}

const GlobalCssPriority: FC<Props> = ({ children }) => (
  <StyledEngineProvider injectFirst>{children}</StyledEngineProvider>
)

export default GlobalCssPriority
