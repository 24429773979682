import { createAsyncThunk } from "@reduxjs/toolkit"
import * as api from "./api"
import { CreateSupplier, Supplier } from "types/Supplier"

import { FetchRequest, FetchAllResponse } from "types/common/FetchRequest"
import handleAPIRequestError, { handleNotificationError } from "utils/handleAPIRequestError"
import { showNotification } from "store/app/slice"

export const fetchSuppliers = createAsyncThunk(
  "suppliers/fetchSuppliers",
  async ({ currentPage, rowsPerPage, keyword }: FetchRequest) => {
    const response = await api.fetchSuppliers(currentPage, rowsPerPage, keyword)
    return response.data as FetchAllResponse<Supplier>
  }
)

export const fetchSupplierById = createAsyncThunk(
  "suppliers/fetchSupplierById",
  async (id: string): Promise<Supplier> => {
    const response = await api.fetchSupplierById(id)
    return response.data
  }
)

export const fetchAvailableManagers = createAsyncThunk(
  "suppliers/fetchAvailableManagers",
  async () => {
    const response = await api.fetchAvailableManagers()
    return response.data
  }
)

export const createSupplier = createAsyncThunk(
  "suppliers/createSupplier",
  async (supplier: CreateSupplier, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.createSupplier(supplier)

      dispatch(showNotification({ status: "success", message: "Tiekėjas sukurtas" }))
      return {
        ...supplier,
        status: 0,
        id: response.data
      } as Supplier
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, "Tiekėjo nepavyko sukurti")
        })
      )
      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)

export const updateSupplier = createAsyncThunk(
  "suppliers/updateSupplier",
  async (supplier: Supplier, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.updateSupplier(supplier)

      dispatch(showNotification({ status: "success", message: "Tiekėjas atnaujintas" }))
      return {
        ...supplier,
        id: response.data
      } as Supplier
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, "Tiekėjo nepavyko atnaujinti")
        })
      )
      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)

export const deleteSupplier = createAsyncThunk(
  "suppliers/deleteSupplier",
  async (id: string, { dispatch }) => {
    try {
      const response = await api.deleteSupplier(id)

      dispatch(showNotification({ status: "success", message: "Tiekėjas ištrintas" }))
      return response.data
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, "Tiekėjo nepavyko ištrinti")
        })
      )
    }
  }
)
