import { lazy } from "react"

import { Route } from "types/common/Route"

const routes: Route[] = [
  {
    path: "/login",
    name: "login",
    title: "Prisijungimo forma",
    component: lazy(() => import("./pages/Login"))
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    title: "Pamiršau slaptažodį",
    component: lazy(() => import("./pages/ForgotPassword"))
  },
  {
    path: "/reset-password",
    name: "reset-password",
    title: "Pakeisti slaptažodį",
    component: lazy(() => import("./pages/ResetPassword"))
  }
]

export default routes
