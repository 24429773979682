import http from "http/http"
import { CreateCanteen, Canteen } from "types/Canteen"

export const fetchCanteens = (currentPage: number, rowsPerPage: number, keyword?: string) =>
  http.post("/v1/canteens/search", {
    pageSize: rowsPerPage,
    pageNumber: currentPage,
    keyword: keyword
  })

export const fetchCanteenById = (id: string) => http.get(`/v1/canteens/${id}`)

export const createCanteen = (canteen: CreateCanteen) => http.post("/v1/canteens", canteen)

export const updateCanteen = (canteen: Canteen) => http.put(`/v1/canteens/${canteen.id}`, canteen)

export const deleteCanteen = (id: string) => http.delete(`/v1/canteens/${id}`)

export const fetchAvailableManagers = () =>
  http.post("/v1/canteens/search-available-managers", {
    pageSize: 999,
    pageNumber: 1
  })
