import http from "http/http"

import { Request, CreateRequest, RequestItem } from "types/Request"

/**
 * Requests API
 */
export const fetchRequests = (
  currentPage: number,
  rowsPerPage: number,
  keyword: string,
  includeDeleted: boolean
) =>
  http.post("/v1/requests/search", {
    pageSize: rowsPerPage,
    pageNumber: currentPage,
    keyword,
    orderBy: ["status desc", "createdOn desc"],
    includeDeleted
  })

export const fetchRequestById = (id: string) => http.get(`/v1/requests/${id}`)

export const createRequest = (request: CreateRequest) => http.post("/v1/requests", request)

export const duplicateRequest = ({ id, withPrices }: { id: string; withPrices: boolean }) =>
  http.post(`/v1/requests/${id}/duplicate?withPrices=${withPrices.toString()}`)

export const updateRequest = (request: Request) => http.put(`/v1/requests/${request.id}`, request)

export const deleteRequest = (id: string) => http.delete(`/v1/requests/${id}`)

interface ChangeStatus {
  id: string
  status: 0 | 1
}
export const updateRequestStatus = (data: ChangeStatus) =>
  http.put(`/v1/requests/change-status/${data.id}`, data)

/**
 * Request Items API
 */
export const fetchRequestItems = (requestId: string) => http.get(`/v1/requestitems/${requestId}`)

export const createRequestItems = (items: RequestItem[]) =>
  http.post(`/v1/requestitems/batch`, { items })

export const createSingleRequestItem = (item: RequestItem) => http.post(`/v1/requestitems`, item)

export const createRequestItem = (item: RequestItem) => http.post(`/v1/requestitems`, item)

export const updateRequestItem = (item: RequestItem) =>
  http.put(`/v1/requestitems/${item.id}`, item)

export const deleteRequestItem = (id: string) => http.delete(`/v1/requestitems/${id}`)

export const fetchRequestOffers = (id: string) => http.get(`/v1/requests/${id}/offers`)
