import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import initialState, { UserStore } from "./state"
import * as thunkActions from "./apiActions"
import { APIRequestError } from "types/common/ApiRequestError"

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setCurrentPage: (state: UserStore, action: PayloadAction<number>) => {
      state.pagination.page = action.payload
    },
    setRowsPerPage: (state: UserStore, action: PayloadAction<number>) => {
      state.pagination.rowsPerPage = action.payload
      state.pagination.page = 1
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunkActions.fetchUsers.pending, (state) => {
        state.loading = "pending"
      })
      .addCase(thunkActions.fetchUsers.fulfilled, (state, action) => {
        const { data, totalCount } = action.payload
        state.items = data
        state.pagination = { ...state.pagination, totalCount }
        state.loading = "idle"
      })
      .addCase(thunkActions.fetchUserById.pending, (state) => {
        state.loading = "pending"
      })
      .addCase(thunkActions.fetchUserById.fulfilled, (state, action) => {
        state.user = action.payload
        state.loading = "idle"
      })
      .addCase(thunkActions.fetchAvailableRoles.fulfilled, (state, action) => {
        state.availableRoles = action.payload.map((role: any) => role.name)
      })
      .addCase(thunkActions.createUser.pending, (state) => {
        state.loading = "pending"
      })
      .addCase(thunkActions.createUser.fulfilled, (state, action) => {
        state.items.push(action.payload)
        state.loading = "succeeded"
      })
      .addCase(thunkActions.createSupplierUser.pending, (state) => {
        state.loading = "pending"
      })
      .addCase(thunkActions.createSupplierUser.fulfilled, (state, action) => {
        state.items.push(action.payload)
        state.loading = "succeeded"
      })
      .addCase(thunkActions.createUser.rejected, (state, action) => {
        const { errors, status, title } = action.payload as APIRequestError
        state.loading = "failed"
        state.error = {
          errors,
          status,
          title
        }
      })
      .addCase(thunkActions.createSupplierUser.rejected, (state, action) => {
        const { errors, status, title } = action.payload as APIRequestError
        state.loading = "failed"
        state.error = {
          errors,
          status,
          title
        }
      })
      .addCase(thunkActions.deleteUser.fulfilled, (state, action) => {
        state.items = state.items.filter((item) => item.id !== action.payload)
        state.loading = "idle"
        state.pagination.totalCount -= 1
      })
  }
})

export const { setCurrentPage, setRowsPerPage } = usersSlice.actions
export default usersSlice.reducer
