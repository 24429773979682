interface ErrorResponse {
  errors: {
    [key: string]: string
  }
  title: string
  status: number
}

const handleAPIRequestError = (err: any): ErrorResponse => {
  if (!err.response) {
    return {
      errors: {},
      title: "Unexpected error",
      status: 0
    }
  }

  // HTTP ERROR 4XX
  if (err.response.data.status) {
    return err.response.data
  } else {
    // HTPP ERROR 5XX
    return {
      errors: {},
      title: "Server Error",
      status: 500
    }
  }
}

export const joinErrorMessages = (errors: any) => Object.values(errors).join()

export const handleNotificationError = (response: any, generalMessage: string) => {
  if (response.status === 400 && response.errors) {
    return joinErrorMessages(response.errors)
  } else if (response.messages) {
    return joinErrorMessages(response.messages)
  } else {
    return generalMessage
  }
}

export default handleAPIRequestError
