import { Request } from "types/Request"

import * as paginationConfig from "constants/pagination"
import { APIRequestError } from "types/common/ApiRequestError"

export interface RequestStore {
  items: Request[]
  loading: "idle" | "pending" | "succeeded" | "failed"

  pagination: paginationConfig.State
  error: APIRequestError
}

const initialState: RequestStore = {
  items: [],
  loading: "idle",

  pagination: paginationConfig.state,
  error: {
    status: 0,
    title: "",
    errors: {}
  }
}

export default initialState
