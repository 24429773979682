import { createSelector } from "@reduxjs/toolkit"
import { RootState } from "store"

export const selectNotification = (state: RootState) => state.app.notification

export const selectLoadingStatus = (state: RootState) => state.app.loading
export const selectSidebarExpanded = (state: RootState) => state.app.sidebar.expanded
export const selectProfile = (state: RootState) => state.app.profile
export const selectUserPermissions = (state: RootState) => state.app.permissions
export const selectIsAdmin = (state: RootState) =>
  state.app.permissions.includes("LogisticsManagers.All")
export const selectError = (state: RootState) => state.users.error

export const selectProfileEmail = createSelector([selectProfile], (profile) =>
  profile ? profile.email : ""
)

export const selectProfileName = createSelector([selectProfile], (profile) =>
  profile ? `${profile.firstName} ${profile.lastName}` : ""
)

export const selectProfileImage = createSelector([selectProfile], (profile) =>
  profile ? profile.imageUrl : ""
)

interface ISelectHasPermission {
  roles: string[]
  permissions: string[]
}
export const selectHasPermission = createSelector(
  [selectUserPermissions, (_, data: ISelectHasPermission) => data],
  (items, { roles, permissions }) => {
    let hasPermissions = false

    if (roles.length === 0) {
      return true
    }

    items.forEach((p) => {
      const values = p.split(".")
      const availablePermission = values[1]
      const hasRole = roles.includes(values[0])

      if (hasRole) {
        if (availablePermission === "All") {
          hasPermissions = true
        } else if (permissions.includes(availablePermission)) {
          hasPermissions = true
        }
      }
    })

    return hasPermissions
  }
)
