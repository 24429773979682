import { lazy } from "react"

import { PrivateRoute } from "types/common/Route"

import { IoGridOutline } from "react-icons/io5"

const routes: PrivateRoute[] = [
  {
    path: "/manage-categories",
    name: "manage-categories",
    title: "Kategorijos",
    icon: IoGridOutline,
    component: lazy(() => import("./pages/ManageCategories")),
    visible: true,
    canAccess: {
      roles: ["Dashboard"], // TODO: Update roles
      permissions: ["View"]
    },
    group: "settings"
  }
]

export default routes
