import { createTheme } from "@mui/material/styles"

export const theme = createTheme({
  // shadows: Array(25).fill("none") as Shadows,
  typography: {
    fontFamily: ["Open Sans", "sans-serif"].join(",")
  },
  palette: {
    primary: {
      main: "#6366f1",
      contrastText: "#fff"
    },
    success: {
      main: "#26AE60",
      contrastText: "#fff"
    },
    info: {
      main: "#3A3FC7",
      contrastText: "#fff"
    }
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: "contained",
        size: "small",
        disableElevation: true
      },
      styleOverrides: {
        root: {
          textTransform: "none"
          // color: '#fff'
        }
      }
    },
    MuiButtonGroup: {
      defaultProps: {
        disableElevation: true
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: "standard",
        size: "small",
        fullWidth: true
      },
      styleOverrides: {
        root: {
          backgroundColor: "#fff"
        }
      }
    }
  }
})
