import { FC, useMemo } from "react"
import { Link, useLocation } from "react-router-dom"

import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"

import { PrivateRoute } from "types/common/Route"

interface Props {
  route: PrivateRoute
  expanded: boolean
}

const MainNavigationItem: FC<Props> = ({ route, expanded }) => {
  const location = useLocation()

  const isSelected = useMemo(() => {
    if (route.path === "/" && location.pathname !== "/") {
      return false
    }

    return location.pathname.includes(route.path)
  }, [location, route])

  return (
    <ListItem disablePadding>
      <ListItemButton
        component={Link}
        to={route.path}
        dense
        className={[
          `flex h-9 items-center px-4 py-2`,
          isSelected ? " text-indigo-700" : "text-gray-700",
          !expanded ? "justify-center" : "justify-start"
        ].join(" ")}
      >
        {route.icon && (
          <ListItemIcon
            className={["min-w-min", isSelected ? "text-indigo-700" : "text-gray-700"].join(" ")}
          >
            <route.icon className='text-md ' />
          </ListItemIcon>
        )}
        {expanded && (
          <ListItemText disableTypography className='ml-2 mt-0'>
            <span className='text-[11px] font-bold uppercase tracking-wider'>{route.title}</span>
          </ListItemText>
        )}
      </ListItemButton>
      {isSelected && (
        <div className='absolute right-0 top-0 bottom-0 w-1 rounded-l-md bg-primary'></div>
      )}
    </ListItem>
  )
}

export default MainNavigationItem
