import http from "http/http"
import {
  PlaceOrder,
  UpdateLineItemStatus,
  UpdateOrderStatus,
  UpdateSupplierDeliveryStatus,
  GroupBy,
  UpdateLineItemQuantity,
  DeleteLineItem,
  AddLineItemsRequest,
  SubmitAllItemsAs,
  UpdateNotesBySupplier,
  UpdateOrderDeliveryNotes
} from "types/Order"

export const fetchOrders = (
  currentPage: number,
  rowsPerPage: number,
  status?: number,
  canteenId?: string,
  supplierId?: string
) =>
  http.post("/v1/orders/search", {
    pageSize: rowsPerPage,
    pageNumber: currentPage,
    status,
    canteenId,
    supplierId,
    orderBy: ["deliveryDateOnUtc DESC"]
  })

export const fetchOrdersGroups = (group: GroupBy) => http.get(`/v1/orders/${group}`)

export const fetchOrder = (id: string) => http.get(`/v1/orders/${id}`)

export const placeOrder = (order: PlaceOrder) => http.post("/v1/orders", order)

export const updateStatus = (data: UpdateOrderStatus) =>
  http.put(`/v1/orders/${data.orderId}/status`, data)

export const updateOrderDeliveryNotes = (data: UpdateOrderDeliveryNotes) =>
  http.patch(`/v1/orders/${data.orderId}/delivery-notes`, data)

export const updateOrderDate = (data: { orderId: string; deliveryDate: string }) =>
  http.patch(`/v1/orders/${data.orderId}/date`, data)

export const updateLineItemStatus = (data: UpdateLineItemStatus) =>
  http.put(`/v1/orders/${data.orderId}/line-items/${data.lineItemId}/status`, data)

export const submitAllItemsAs = (data: SubmitAllItemsAs) =>
  http.put(`/v1/orders/${data.orderId}/line-items/status`, data)

export const updateSupplierDeliveryStatus = (data: UpdateSupplierDeliveryStatus) =>
  http.post(`/v1/orders/${data.orderId}/suppliers/${data.supplierId}/status`, data)

export const updateSupplierDeliveryQuantity = (data: UpdateLineItemQuantity) =>
  http.patch(`/v1/orders/${data.orderId}/line-items/${data.lineItemId}`, data)

export const addLineItems = (data: AddLineItemsRequest) =>
  http.post(`/v1/orders/${data.orderId}/line-items`, data)

export const deleteLineItem = (data: DeleteLineItem) =>
  http.delete(`/v1/orders/${data.orderId}/line-items/${data.lineItemId}`)

export const updateDeliveryNotesBySupplier = ({
  orderId,
  deliveryNotesPerSupplier
}: UpdateNotesBySupplier) =>
  http.patch(`/v1/orders/${orderId}/delivery-notes`, { deliveryNotesPerSupplier })
