import http from "http/http"
import { CreateOrderTemplate } from "types/OrderTemplate"

export const fetchOrderTemplateById = (id: string) => http.get(`/v1/ordertemplates/${id}`)

export const fetchAllOrderTemplates = (canteenId: string | null) =>
  http.post("/v1/ordertemplates/search", {
    pageNumber: 0,
    pageSize: 999,
    canteenId
  })

export const createOrderTemplate = (data: CreateOrderTemplate) =>
  http.post("/v1/ordertemplates", data)

export const deleteOrderTemplateById = (id: string) => http.delete(`/v1/ordertemplates/${id}`)

export const fetchCatalogItems = (
  currentPage: number,
  rowsPerPage: number,
  keyword: string = "",
  categoryIds: string[] = []
) =>
  http.post("/v1/catalogitems/search", {
    pageSize: rowsPerPage,
    pageNumber: currentPage,
    keyword,
    categoryIds
  })
