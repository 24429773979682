import http from "http/http"
import { ForgotPassword } from "../types/ForgotPassword"
import { ResetPassword } from "../types/ResetPassword"

export const loginUser = async (email: string, password: string) => {
  const response = await http.post(
    "/tokens",
    { email, password },
    {
      headers: {
        tenant: "root"
      }
    }
  )

  return response
}

export const forgotPassword = async (data: ForgotPassword) =>
  http.post(`/users/forgot-password`, data, {
    headers: {
      tenant: "root"
    }
  })

export const resetPassword = async (data: ResetPassword) =>
  http.post(`/users/reset-password`, data, {
    headers: {
      tenant: "root"
    }
  })
