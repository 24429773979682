import { FC, ReactNode, useEffect, memo } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { selectHasPermission } from "store/app/selector"
import { selectIsAuthenticated } from "pages/auth/store/selector"

interface Props {
  title: string
  children: ReactNode

  roles: string[]
  permissions: string[]
}

const ProtectedRoute: FC<Props> = ({ title, children, roles, permissions }) => {
  const navigate = useNavigate()
  const isAuthenticated = useSelector(selectIsAuthenticated)

  const hasPermission = useSelector((state: any) =>
    selectHasPermission(state, { roles, permissions })
  )

  useEffect(() => {
    document.title = `${title} ― ${process.env.REACT_APP_NAME}`
  }, [title])

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login")
    }
  }, [isAuthenticated, navigate])

  useEffect(() => {
    if (isAuthenticated && !hasPermission) {
      navigate("/no-permissions")
    }
  }, [isAuthenticated, hasPermission, navigate])

  return <>{children}</>
}

export default memo(ProtectedRoute)
