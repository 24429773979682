import { Category } from "types/Category"
import { LoadingStatus } from "types/common/LoadingStatus"

import { APIRequestError } from "types/common/ApiRequestError"

export interface CategoryStore {
  items: Category[]
  loading: LoadingStatus

  error: APIRequestError
}

const initialState: CategoryStore = {
  items: [],
  loading: "idle",

  error: {
    status: 0,
    title: "",
    errors: {}
  }
}

export default initialState
