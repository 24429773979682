import { lazy } from "react"

import { PrivateRoute } from "types/common/Route"

import { IoCartOutline } from "react-icons/io5"

const routes: PrivateRoute[] = [
  {
    path: "/marketplace",
    name: "marketplace",
    title: "Parduotuvė",
    component: lazy(() => import("./pages/Marketplace")),
    visible: true,
    icon: IoCartOutline,
    canAccess: {
      roles: ["LogisticsManagers", "CanteenManagers"],
      permissions: ["View"]
    }
  },
  {
    path: "/order-checkout",
    name: "order-checkout",
    title: "Užsakymo patvirtinimas",
    component: lazy(() => import("./pages/Checkout")),
    visible: false,
    canAccess: {
      roles: ["LogisticsManagers", "CanteenManagers"],
      permissions: ["View", "All"]
    }
  }
]

export default routes
