import { createAsyncThunk } from "@reduxjs/toolkit"
import { CreateRequest, Request, RequestItem } from "types/Request"
import * as api from "./api"

import { FetchRequest, FetchAllResponse } from "types/common/FetchRequest"
import handleAPIRequestError, { handleNotificationError } from "utils/handleAPIRequestError"
import { showNotification } from "store/app/slice"

/**
 * Request actions
 */
interface RequestSearch extends FetchRequest {
  includeDeleted: boolean
}
export const fetchRequests = createAsyncThunk(
  "requests/fetchRequests",
  async ({ currentPage, rowsPerPage, keyword, includeDeleted }: RequestSearch) => {
    const response = await api.fetchRequests(currentPage, rowsPerPage, keyword, includeDeleted)
    return response.data as FetchAllResponse<Request>
  }
)

export const fetchRequestById = createAsyncThunk(
  "requests/fetchRequestById",
  async (id: string): Promise<Request> => {
    const response = await api.fetchRequestById(id)
    return response.data
  }
)

export const createRequest = createAsyncThunk(
  "requests/createRequest",
  async (request: CreateRequest, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.createRequest(request)

      dispatch(showNotification({ status: "success", message: "Užklausa sukurta" }))

      return {
        ...request,
        status: 0,
        id: response.data
      } as Request
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, "Užklausos nepavyko sukurti")
        })
      )

      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)

export const duplicateRequest = createAsyncThunk(
  "requests/duplicateRequest",
  async (values: { id: string; withPrices: boolean }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.duplicateRequest(values)

      dispatch(showNotification({ status: "success", message: "Užklausa dublikuota" }))
      return response.data as string
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, "Užklausos nepavyko dublikuoti")
        })
      )
      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)

export const updateRequest = createAsyncThunk(
  "requests/updateRequest",
  async (request: Request, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.updateRequest(request)
      dispatch(showNotification({ status: "success", message: "Užklausa atnaujinta" }))
      return {
        ...request,
        id: response.data
      } as Request
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "success",
          message: handleNotificationError(error.response.data, "Užklausa dublikuota")
        })
      )
      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)

export const updateRequestStatus = createAsyncThunk(
  "requests/updateRequestStatus",
  async (data: { id: string; status: 0 | 1 }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.updateRequestStatus(data)
      dispatch(showNotification({ status: "success", message: "Užklausos statusas pakeistas" }))
      return response.data as string
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, "Užklausos statusas nepakeistas")
        })
      )
      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)

export const deleteRequest = createAsyncThunk(
  "requests/deleteRequest",
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.deleteRequest(id)

      dispatch(showNotification({ status: "success", message: "Užklausa ištrinta" }))
      return response.data as string
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, "Užklausos nepavyko ištrinti")
        })
      )
      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)

/**
 * Request item actions
 */
export const fetchRequestItems = createAsyncThunk(
  "requests/getItems",
  async (requestId: string): Promise<RequestItem[]> => {
    const response = await api.fetchRequestItems(requestId)

    return response.data
  }
)

export const createRequestItems = createAsyncThunk(
  "requests/createRequestItems",
  async (items: RequestItem[], { dispatch, rejectWithValue }) => {
    try {
      const response = await api.createRequestItems(items)
      dispatch(showNotification({ status: "success", message: "Produktai pridėti prie užklausos" }))
      return response.data as RequestItem[]
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(
            error.response.data,
            "Nepavyko pridėti produktų prie užklausos"
          )
        })
      )

      return rejectWithValue(error.response.data.status)
    }
  }
)

export const createRequestItem = createAsyncThunk(
  "requests/createRequestItem",
  async (item: RequestItem, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.createRequestItem(item)
      dispatch(
        showNotification({ status: "success", message: "Produktas pridėtas prie užklausos" })
      )
      return response.data as string
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(
            error.response.data,
            "Nepavyko pridėti produkto prie užklausos"
          )
        })
      )
      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)

export const updateRequestItem = createAsyncThunk(
  "requests/updateRequestItem",
  async (item: RequestItem, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.updateRequestItem(item)
      // dispatch(showNotification({ status: "success", message: "Produktas atnaujintas" }))
      return response.data
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, "Nepavyko atnaujinti produkto")
        })
      )
      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)

export const deleteRequestItem = createAsyncThunk(
  "requests/deleteRequestItem",
  async (id: string, { dispatch }) => {
    try {
      const response = await api.deleteRequestItem(id)
      dispatch(showNotification({ status: "success", message: "Produktas ištrintas" }))
      return response.data
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, "Nepavyko ištrinti produkto")
        })
      )
    }
  }
)

export const fetchRequestOffers = createAsyncThunk(
  "requests/getSubmittedOffers",
  async (requestId: string) => {
    const response = await api.fetchRequestOffers(requestId)

    return response.data
  }
)
