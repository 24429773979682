import { createAsyncThunk } from "@reduxjs/toolkit"
import * as api from "./api"

import handleAPIRequestError from "utils/handleAPIRequestError"
import { ResetPassword } from "../types/ResetPassword"
import { LoginRequest } from "../types/LoginRequest"
import { ForgotPassword } from "../types/ForgotPassword"

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async ({ email, password }: LoginRequest) => {
    const response = await api.loginUser(email, password)

    return {
      data: response.data
    }
  }
)

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (data: ForgotPassword, { rejectWithValue }) => {
    try {
      const response = await api.forgotPassword(data)

      return response.data as string
    } catch (error) {
      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (data: ResetPassword, { rejectWithValue }) => {
    try {
      const response = await api.resetPassword(data)
      return response.data as string
    } catch (error) {
      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)
