import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import initialState, { MarketplaceStore } from "./state"
import { CatalogItem, ProductInCart } from "types/Product"
import { deleteOrderTemplateById, fetchAllOrderTemplates, fetchCatalogItems } from "./apiActions"
import { OrderTemplateFull } from "types/OrderTemplate"

export const marketplaceSlice = createSlice({
  name: "marketplace",
  initialState,
  reducers: {
    setCurrentPage: (state: MarketplaceStore, action: PayloadAction<number>) => {
      state.pagination.page = action.payload
    },
    setRowsPerPage: (state: MarketplaceStore, action: PayloadAction<number>) => {
      state.pagination.rowsPerPage = action.payload
    },

    addProductToCart: (state: MarketplaceStore, action: PayloadAction<CatalogItem>) => {
      state.cart.push({ ...action.payload, quantity: 1 })
    },

    updateProductInCart: (
      state: MarketplaceStore,
      action: PayloadAction<{ id: string; quantity: number }>
    ) => {
      const { id, quantity } = action.payload
      const cart = [...state.cart]
      const product = cart.find((x: ProductInCart) => x.id === id)
      if (product) {
        if (quantity > 0) {
          product.quantity = quantity
        } else {
          const index = cart.findIndex((x) => x.id === product.id)
          cart.splice(index, 1)
        }
      }

      state.cart = [...cart]
    },

    copyPageCartItems: (state: MarketplaceStore) => {
      state.copiedCart = [...state.cart]
      state.cart = []
    },

    moveCopiedCartItemsToOriginal: (state: MarketplaceStore) => {
      state.cart = [...state.copiedCart]
      state.copiedCart = []
    },

    removeProductFromCart: (state: MarketplaceStore, action: PayloadAction<string>) => {
      state.cart = state.cart.filter((x: ProductInCart) => x.id !== action.payload)
    },

    removeAllProductsFromCart: (state: MarketplaceStore) => {
      state.cart = []
    },

    applyTemplateToCart: (state: MarketplaceStore, action: PayloadAction<OrderTemplateFull>) => {
      const lineItems = action.payload.lineItems
      const ids = lineItems.map((x) => x.catalogItemId)
      state.cart = lineItems
        .filter((x) => !x.catalogItemNotFound)
        .filter((x) => {
          const foundIds = ids.filter((y) => y === x.catalogItemId)

          if (foundIds.length > 1) {
            return x.originalSupplierName === x.supplierName
          }

          return true
        })
        .map((x) => ({
          id: x.catalogItemId,
          name: x.name,
          unitPrice: x.unitPrice,
          unitName: x.unitName,

          quantity: x.quantity,

          categoryId: x.categoryId,
          categoryName: x.categoryName,

          supplierId: x.supplierId,
          supplierName: x.supplierName,
          originalSupplierName: x.originalSupplierName || x.supplierName,

          comment: "Pridėtas iš krepšelio ruošinio"
        }))
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCatalogItems.fulfilled, (state, action) => {
        const { data, totalCount, totalPages } = action.payload
        state.marketplaceItems = data
        state.pagination = {
          ...state.pagination,
          totalCount,
          totalPages
        }
        state.loading = "idle"
      })
      .addCase(fetchAllOrderTemplates.fulfilled, (state, action) => {
        state.orderTemplates = action.payload.data
      })
      .addCase(deleteOrderTemplateById.fulfilled, (state, action) => {
        state.orderTemplates = state.orderTemplates.filter((x) => x.id !== action.payload)
      })
  }
})

export const {
  setCurrentPage,
  setRowsPerPage,
  addProductToCart,
  updateProductInCart,
  removeProductFromCart,
  removeAllProductsFromCart,
  copyPageCartItems,
  moveCopiedCartItemsToOriginal,
  applyTemplateToCart
} = marketplaceSlice.actions

export default marketplaceSlice.reducer
