export interface State {
  rowsPerPage: number
  page: number
  totalCount: number
  totalPages: number
}

export const state: State = {
  rowsPerPage: 10,
  page: 1,
  totalCount: 0,
  totalPages: 0
}
