import http from "http/http"

import { Product, CreateProduct } from "types/Product"

export const fetchProducts = (
  currentPage: number,
  rowsPerPage: number,
  keyword: string,
  advancedSearch: any
) =>
  http.post("/v1/products/search", {
    pageSize: rowsPerPage,
    pageNumber: currentPage,
    keyword,
    advancedSearch
  })

export const fetchProductById = (id: string) => http.get(`/v1/products/${id}`)

export const createProduct = (product: CreateProduct) => http.post("/v1/products", product)
export const updateProduct = (product: Product) => http.put(`/v1/products/${product.id}`, product)
export const deleteProduct = (id: string) => http.delete(`/v1/products/${id}`)
