import { Canteen } from "types/Canteen"
import { ManagingUser } from "types/User"
import { LoadingStatus } from "types/common/LoadingStatus"

import * as paginationConfig from "constants/pagination"
import { APIRequestError } from "types/common/ApiRequestError"

export interface CanteenStore {
  loading: LoadingStatus
  items: Canteen[]
  availableManagers: ManagingUser[]
  pagination: paginationConfig.State
  error: APIRequestError
}

const initialState: CanteenStore = {
  items: [],
  availableManagers: [],
  pagination: paginationConfig.state,
  loading: "idle",
  error: {
    status: 0,
    title: "",
    errors: {}
  }
}

export default initialState
