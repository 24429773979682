import storage from "redux-persist/lib/storage"

export const root = {
  key: "root",
  storage,
  whitelist: ["app"]
}

export const auth = {
  key: "auth",
  storage,
  blacklist: ["loading"]
}

export const marketplace = {
  key: "marketplace",
  storage,
  whitelist: ["cart"]
}

export const orders = {
  key: "orders",
  storage,
  whitelist: ["groupBy", "rowFocusOn", "pagination"]
}
