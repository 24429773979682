import { FC, ReactNode, useEffect, useCallback, memo } from "react"

import { useDispatch, useSelector } from "react-redux"
import { AppDispatch } from "store"
import { setIsAuthenticated } from "pages/auth/store/slice"
import { fetchPersonalProfile, fetchProfilePermissions } from "store/app/apiActions"
import { selectLoadingStatus, selectProfile } from "store/app/selector"
import { selectIsAuthenticated, selectToken } from "pages/auth/store/selector"
import Loader from "components/common/Loader"

interface Props {
  children: ReactNode
}

const UserAccessLayer: FC<Props> = ({ children }) => {
  const dispatch = useDispatch<AppDispatch>()
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const loading = useSelector(selectLoadingStatus)
  const token = useSelector(selectToken)
  const userProfile = useSelector(selectProfile)

  const fetchUserProfile = useCallback(() => {
    dispatch(fetchPersonalProfile())
      .unwrap()
      .then(() => {
        dispatch(fetchProfilePermissions())
          .unwrap()
          .then(() => {
            dispatch(setIsAuthenticated(true))
          })
      })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isAuthenticated && !userProfile) {
      fetchUserProfile()
    } else {
      if (token && token !== "" && !userProfile) {
        fetchUserProfile()
      }
    }

    // eslint-disable-next-line
  }, [])

  if (isAuthenticated && loading) {
    return <Loader />
  }

  return <>{children}</>
}

export default memo(UserAccessLayer)
