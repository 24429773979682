import http from "http/http"

import { Supplier, CreateSupplier } from "types/Supplier"

export const fetchSuppliers = (currentPage: number, rowsPerPage: number, keyword: string) =>
  http.post("/v1/suppliers/search", {
    pageSize: rowsPerPage,
    pageNumber: currentPage,
    keyword
  })

export const fetchSupplierById = (id: string) => http.get(`/v1/suppliers/${id}`)

export const createSupplier = (supplier: CreateSupplier) => http.post("/v1/suppliers", supplier)

export const updateSupplier = (supplier: Supplier) =>
  http.put(`/v1/suppliers/${supplier.id}`, supplier)

export const deleteSupplier = (id: string) => http.delete(`/v1/suppliers/${id}`)

export const fetchAvailableManagers = () =>
  http.post("/v1/suppliers/search-available-managers", {
    pageSize: 999,
    pageNumber: 1
  })
