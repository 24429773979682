import { createAsyncThunk } from "@reduxjs/toolkit"
import { CreateProduct, Product } from "types/Product"
import * as api from "./api"

import { FetchRequest, FetchAllResponse } from "types/common/FetchRequest"
import handleAPIRequestError, { handleNotificationError } from "utils/handleAPIRequestError"
import { showNotification } from "store/app/slice"

export const fetchProducts = createAsyncThunk(
  "products/fetchProducts",
  async ({ currentPage, rowsPerPage, keyword, advancedSearch }: FetchRequest) => {
    const response = await api.fetchProducts(currentPage, rowsPerPage, keyword, advancedSearch)
    return response.data as FetchAllResponse<Product>
  }
)

export const fetchProductById = createAsyncThunk(
  "products/fetchProductById",
  async (id: string): Promise<Product> => {
    const response = await api.fetchProductById(id)
    return response.data
  }
)

export const createProduct = createAsyncThunk(
  "products/createProduct",
  async (product: CreateProduct, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.createProduct(product)

      dispatch(showNotification({ status: "success", message: "Produktas sukurtas" }))
      return {
        ...product,
        id: response.data
      } as Product
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, "Nepavyko pridėti produkto")
        })
      )
      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)

export const updateProduct = createAsyncThunk(
  "products/updateProduct",
  async (product: Product, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.updateProduct(product)
      dispatch(showNotification({ status: "success", message: "Produktas atnaujintas" }))
      return {
        ...product,
        id: response.data
      } as Product
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, "Nepavyko atnaujinti produkto")
        })
      )
      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)

export const deleteProduct = createAsyncThunk(
  "products/deleteProduct",
  async (id: string, { dispatch }) => {
    try {
      const response = await api.deleteProduct(id)
      dispatch(showNotification({ status: "success", message: "Produktas ištrintas" }))
      return response.data as string
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, "Nepavyko ištrinti produkto")
        })
      )
    }
  }
)
