import { lazy } from "react"

import { PrivateRoute } from "types/common/Route"

const routes: PrivateRoute[] = [
  {
    path: "/no-permissions",
    name: "no-permission",
    title: "Neturite teisių",
    component: lazy(() => import("./pages/NoPermissions")),
    visible: false,
    canAccess: {
      roles: [],
      permissions: []
    }
  }
]

export default routes
