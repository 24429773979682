import { GroupBy, Order } from "types/Order"
import { LoadingStatus } from "types/common/LoadingStatus"

import * as paginationConfig from "constants/pagination"
export interface OrderStore {
  items: Order[]
  viewOrder: Order | null
  loading: LoadingStatus
  groupBy: GroupBy
  rowFocusOn: number
  pagination: paginationConfig.State
}

const initialState: OrderStore = {
  items: [],
  viewOrder: null,
  groupBy: "suppliers",
  rowFocusOn: -1,
  loading: "idle",
  pagination: paginationConfig.state
}

export default initialState
