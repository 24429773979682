import { FC, Suspense } from "react"

import { Routes, Route } from "react-router-dom"
import routes from "router/routes"
import ProtectedRoute from "router/ProtectedRoute"

import Loader from "components/common/Loader"
import UserAccessLayer from "layers/UserAccessLayer"
import DashboardComposition from "components/compositions/DashboardComposition"

const App: FC = () => {
  return (
    <div className='overflow-hidden'>
      <UserAccessLayer>
        <Suspense fallback={<Loader />}>
          <Routes>
            {routes.public
              .filter((route) => route.component)
              .map((route) => (
                <Route key={route.name} path={route.path} element={<route.component />} />
              ))}

            <Route path='/' element={<DashboardComposition />}>
              {routes.private.map((route) => (
                <Route
                  key={route.name}
                  path={route.path}
                  element={
                    <ProtectedRoute
                      title={route.title}
                      roles={route.canAccess.roles}
                      permissions={route.canAccess.permissions}
                    >
                      <route.component />
                    </ProtectedRoute>
                  }
                />
              ))}
            </Route>
          </Routes>
        </Suspense>
      </UserAccessLayer>
    </div>
  )
}

export default App
