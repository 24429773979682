import { lazy } from "react"

import { Route } from "types/common/Route"

// import DescriptionIcon from "@mui/icons-material/Description"

const routes: Route[] = [
  {
    path: "/received-requests",
    name: "received-requests",
    title: "Gautos užklausos",
    component: lazy(() => import("./pages/ReceivedRequests"))
    // visible: false,
    // icon: DescriptionIcon
  },
  {
    path: "/request-offer/:requestId/:supplierId",
    name: "request-offer",
    title: "Atnaujinti užklausos pasiūlymą",
    component: lazy(() => import("./pages/RequestOffer"))
    // visible: false
  }
]

export default routes
