import ReactDOM from "react-dom/client"

import "./styles/index.css"
import GlobalCssPriority from "styles/GlobalCssPriority"
import { ThemeProvider } from "@mui/material/styles"

import { theme } from "styles/mui-theme"

import reportWebVitals from "./reportWebVitals"

import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { store, persistor } from "./store"

import Notifications from "components/Notifications"
import LocalizationAdapter from "components/LocalizationAdapter"

import App from "./App"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

const init = (): void => {
  root.render(
    <GlobalCssPriority>
      <div id='popper-portal'></div>
      <LocalizationAdapter>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Provider store={store}>
              <PersistGate persistor={persistor}>
                <Notifications />
                <App />
              </PersistGate>
            </Provider>
          </BrowserRouter>
        </ThemeProvider>
      </LocalizationAdapter>
    </GlobalCssPriority>
  )
}

init()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
