import { lazy } from "react"

import { PrivateRoute } from "types/common/Route"

import { IoFileTrayFullOutline } from "react-icons/io5"

const routes: PrivateRoute[] = [
  {
    path: "/orders",
    name: "order-list",
    title: "Užsakymai",
    component: lazy(() => import("./pages/List")),
    visible: true,
    icon: IoFileTrayFullOutline,
    canAccess: {
      roles: ["Orders"],
      permissions: ["All"]
    }
  },
  {
    path: "/orders/view/:id",
    name: "order-view",
    title: "Peržiūrėti užsakymą",
    component: lazy(() => import("./wrappers/ViewWithContext")),
    visible: false,
    canAccess: {
      roles: ["Orders"],
      permissions: ["All"]
    }
  }
]

export default routes
