import http from "http/http"
import { CreateUser, UpdateUser } from "types/User"

export const fetchUsers = (currentPage: number, rowsPerPage: number) =>
  http.post("/users/search", {
    pageSize: rowsPerPage,
    pageNumber: currentPage
  })

export const fetchUserById = (id: string) => http.get(`/users/${id}`)

export const fetchAvailableRoles = () => http.get("/roles")

export const createUser = (user: CreateUser) => http.post("/users", user)
export const updateUser = (user: UpdateUser) => http.put(`/users/${user.id}`, user)
export const createSupplierUser = (user: CreateUser) =>
  http.post("/users?sendConfirmation=false&autogeneratePassword=true", user)

export const updateUserRole = (user: UpdateUser) =>
  http.post(`/users/${user.id}/roles`, {
    userRoles: [
      {
        roleName: user.role,
        enabled: true
      }
    ]
  })

export const deleteUser = (id: string) => http.delete(`/users/${id}`)
