import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import initialState, { CatalogItemsStore } from "./state"
import * as thunkActions from "./apiActions"
import { APIRequestError } from "types/common/ApiRequestError"
import { CatalogItem } from "types/Product"

export const catalogItemsSlice = createSlice({
  name: "catalogItems",
  initialState,
  reducers: {
    setCurrentPage: (state: CatalogItemsStore, action: PayloadAction<number>) => {
      state.pagination.page = action.payload
    },
    setRowsPerPage: (state: CatalogItemsStore, action: PayloadAction<number>) => {
      state.pagination.rowsPerPage = action.payload
      state.pagination.page = 1
    },

    setUpdateEntity: (state: CatalogItemsStore, action: PayloadAction<CatalogItem>) => {
      state.entity = action.payload
    },

    clearUpdateEntity: (state: CatalogItemsStore) => {
      state.entity = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunkActions.fetchCatalogItems.fulfilled, (state, action) => {
        const { data, totalCount, totalPages } = action.payload
        state.items = data
        state.pagination = {
          ...state.pagination,
          totalCount,
          totalPages
        }
        state.loading = "idle"
      })
      .addCase(thunkActions.addCatalogItem.pending, (state) => {
        state.loading = "pending"
      })
      .addCase(thunkActions.addCatalogItem.fulfilled, (state, action) => {
        state.loading = "succeeded"
        state.error = {
          errors: {},
          status: 200,
          title: ""
        }
      })
      .addCase(thunkActions.updateCatalogItem.fulfilled, (state, action) => {
        state.loading = "succeeded"
        state.error = {
          errors: {},
          status: 200,
          title: ""
        }
      })
      .addCase(thunkActions.addCatalogItem.rejected, (state, action) => {
        const { errors, status, title } = action.payload as APIRequestError
        state.loading = "failed"
        state.error = {
          errors,
          status,
          title
        }
      })
      .addCase(thunkActions.updateCatalogItem.rejected, (state, action) => {
        const { errors, status, title } = action.payload as APIRequestError
        state.loading = "failed"
        state.error = {
          errors,
          status,
          title
        }
      })
      .addCase(thunkActions.deleteCatalogItem.pending, (state) => {
        state.loading = "pending"
      })
      .addCase(thunkActions.deleteCatalogItem.fulfilled, (state, action) => {
        state.items = state.items.filter((item) => item.id !== action.payload)
        state.loading = "idle"
        state.pagination.totalCount -= 1
      })
  }
})

export const { setCurrentPage, setRowsPerPage, setUpdateEntity, clearUpdateEntity } =
  catalogItemsSlice.actions

export default catalogItemsSlice.reducer
