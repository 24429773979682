import { lazy } from "react"

import { PrivateRoute } from "types/common/Route"

import { IoBusinessSharp } from "react-icons/io5"

const routes: PrivateRoute[] = [
  {
    path: "/suppliers",
    name: "suppliers",
    title: "Tiekėjai",
    component: lazy(() => import("./pages/List")),
    icon: IoBusinessSharp,
    visible: true,
    canAccess: {
      roles: ["LogisticsManagers"],
      permissions: ["View"]
    },
    group: "settings"
  },
  {
    path: "/suppliers/create",
    name: "create-supplier",
    title: "Sukurti tiekėją",
    component: lazy(() => import("./pages/Create")),
    visible: false,
    canAccess: {
      roles: ["LogisticsManagers"],
      permissions: ["View", "Create"]
    },
    group: "settings"
  },
  {
    path: "/suppliers/update/:id",
    name: "update-supplier",
    title: "Atnaujinti tiekėją",
    component: lazy(() => import("./pages/Update")),
    visible: false,
    canAccess: {
      roles: ["LogisticsManagers"],
      permissions: ["View", "Update"]
    },
    group: "settings"
  }
]

export default routes
