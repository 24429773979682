import { createAsyncThunk } from "@reduxjs/toolkit"
import * as api from "./api"

import { FetchRequest, FetchAllResponse } from "types/common/FetchRequest"
import handleAPIRequestError, { handleNotificationError } from "utils/handleAPIRequestError"
import { RequestOffer, RequestOfferItem } from "types/RequestOffer"
import { showNotification } from "store/app/slice"

/**
 * Request actions
 */
export const fetchRequestOffers = createAsyncThunk(
  "requestOffers/fetchRequestOffers",
  async ({ currentPage, rowsPerPage }: FetchRequest) => {
    const response = await api.fetchRequestOffers(currentPage, rowsPerPage)
    return response.data as FetchAllResponse<RequestOffer>
  }
)

export const fetchOfferById = createAsyncThunk(
  "requestOffers/fetchOfferBYid",
  async (id: string) => {
    const response = await api.fetchOfferById(id)
    return response.data as RequestOffer
  }
)

interface FetchRequestById {
  requestId: string
  supplierId: string
}
export const fetchPublicOfferById = createAsyncThunk(
  "requestOffers/fetchRequestById",
  async ({ requestId, supplierId }: FetchRequestById): Promise<RequestOffer> => {
    const response = await api.fetchPublicOfferById(requestId, supplierId)
    return response.data
  }
)

export const updateRequestOffer = createAsyncThunk(
  "requestOffers/updateRequestOffer",
  async (offer: RequestOffer, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.updateRequestOffer(offer)
      dispatch(showNotification({ status: "success", message: "Pasiūlymas atnaujintas" }))
      return {
        ...offer,
        id: response.data
      } as RequestOffer
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, "Pasiūlymo nepavyko atnaujinti")
        })
      )
      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)

interface UpdateOfferStatus {
  id: string
  status: 0 | 1 | 2 | 3
}
export const updateRequestOfferStatus = createAsyncThunk(
  "requestOffers/updateRequestOfferStatus",
  async (data: UpdateOfferStatus, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.updateRequestOfferStatus(data)
      dispatch(showNotification({ status: "success", message: "Pasiūlymo statusas atnaujintas" }))
      return response.data as string
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, "Pasiūlymo statusas nepakeistas")
        })
      )
      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)

interface ReactivateOffer {
  id: string
  priceGuaranteeUntilUtc: string
}
export const reactivateOffer = createAsyncThunk(
  "requestOffers/reactivateOffer",
  async (data: ReactivateOffer, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.reactivateOffer(data)
      dispatch(showNotification({ status: "success", message: "Pasiūlymas aktyvuotas" }))
      return response.data as string
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, "Pasiūlymo nepavyko aktyvuoti")
        })
      )
      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)

export const updateRequestOfferItem = createAsyncThunk(
  "requestOffers/updateOfferItem",
  async (item: RequestOfferItem, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.updateRequestOfferItem(item)
      dispatch(showNotification({ status: "success", message: "Produktas atnaujintas" }))
      return response.data
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, "Produkto nepavyko atnaujinti")
        })
      )

      return rejectWithValue(error.response.data.status)
    }
  }
)
