import http from "http/http"
import { RequestOffer, RequestOfferItem } from "types/RequestOffer"

export const fetchRequestOffers = (currentPage: number, rowsPerPage: number) =>
  http.post("/v1/offers/search", {
    pageSize: rowsPerPage,
    pageNumber: currentPage
  })

export const fetchOfferById = (id: string) => http.get(`/v1/offers/${id}`)

export const fetchPublicOfferById = (requestId: string, supplierId: string) =>
  http.get(`/v1/offers?requestId=${requestId}&supplierId=${supplierId}&tenant=root`)

export const updateRequestOffer = (offer: RequestOffer) => http.put(`/v1/offers/${offer.id}`, offer)

interface UpdateOfferStatus {
  id: string
  status: 0 | 1 | 2 | 3
}
export const updateRequestOfferStatus = ({ id, status }: UpdateOfferStatus) =>
  http.put(
    `/v1/offers/change-status/${id}`,
    { id, status },
    {
      headers: {
        tenant: "root"
      }
    }
  )

interface ReactivateOffer {
  id: string
  priceGuaranteeUntilUtc: string
}

export const reactivateOffer = (values: ReactivateOffer) =>
  http.put(`/v1/offers/reactivate/${values.id}`, values)

export const updateRequestOfferItem = (item: RequestOfferItem) =>
  http.put(`/v1/offeritems/${item.id}`, item, {
    headers: {
      tenant: "root"
    }
  })
