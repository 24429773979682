import { FC, useMemo } from "react"

import { Link } from "react-router-dom"

interface Props {
  expanded: boolean
}

const HeaderBrand: FC<Props> = ({ expanded }) => {
  const appName = useMemo(() => {
    return expanded ? "Blnfood" : "BF"
  }, [expanded])

  return (
    <div className='mb-4 flex h-12 items-center px-4'>
      <Link to='/' className='inline-flex h-full w-full items-center no-underline'>
        <h2 className='m-0 ml-2 text-sm uppercase tracking-wider text-primary'>{appName}</h2>
      </Link>
    </div>
  )
}

export default HeaderBrand
