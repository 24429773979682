import * as paginationConfig from "constants/pagination"
import { APIRequestError } from "types/common/ApiRequestError"
import { RequestOffer } from "types/RequestOffer"

export interface RequestOfferStore {
  items: RequestOffer[]
  loading: "idle" | "pending" | "succeeded" | "failed"

  pagination: paginationConfig.State
  error: APIRequestError
}

const initialState: RequestOfferStore = {
  items: [],
  loading: "idle",

  pagination: paginationConfig.state,
  error: {
    status: 0,
    title: "",
    errors: {}
  }
}

export default initialState
