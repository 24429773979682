import axios from "axios"
import { store } from "../store"
import { refreshToken, logout } from "../pages/auth/store/slice"
import { isBefore } from "date-fns"

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Access-Control-Allow-Origin": "*"
  }
})

http.interceptors.request.use(
  (config) => {
    const token = store.getState().auth.token
    if (token) {
      config.headers!.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => error
)

http.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config

    if (error.response.status === 403) {
      console.warn("No permissions")
      window.location.href = "/no-permissions"
    }

    if (
      error.response.status === 401 &&
      originalRequest.url !== "/tokens" &&
      originalRequest.url !== "/tokens/refresh" &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true
      const token = await refreshAccessToken()
      
      if (!token) {
        store.dispatch(logout())
        window.location.href = "/login"
      }

      if (token && token !== "") {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
        return http(originalRequest)
      }
    } else {
      const expireDate = store.getState().auth.refreshTokenExpiryTime

      if (
        (expireDate && isBefore(new Date(expireDate), new Date())) ||
        (error.response.status === 401 && !originalRequest._retry)
      ) {
        console.warn("Token expired")
        store.dispatch(logout())

        window.location.href = "/login"
      }
    }

    return Promise.reject(error)
  }
)

const refreshAccessToken = async () => {
  const tokenOld = store.getState().auth.token
  const refreshTokenOld = store.getState().auth.refreshToken

  if (tokenOld && tokenOld !== "" && refreshTokenOld && refreshTokenOld !== "") {
    const response = await http.post(
      "/tokens/refresh",
      {
        token: tokenOld,
        refreshToken: refreshTokenOld
      },
      {
        headers: {
          tenant: "root"
        }
      }
    )

    store.dispatch(refreshToken(response.data))

    return response.data.token
  } else {
    return undefined
  }
}

export default http
