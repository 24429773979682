import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import initialState, { AuthStore } from "./state"

import * as thunkActions from "./apiActions"

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsAuthenticated: (state: AuthStore, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload
    },

    /**
     * Refresh authentication token
     */
    refreshToken: (
      state: AuthStore,
      action: PayloadAction<{ token: string; refreshToken: string; refreshTokenExpiryTime: string }>
    ) => {
      const { token, refreshToken, refreshTokenExpiryTime } = action.payload

      if (!token || !refreshToken || !refreshTokenExpiryTime) {
        return
      }

      state.token = token
      state.refreshToken = refreshToken
      state.refreshTokenExpiryTime = refreshTokenExpiryTime
    },

    logout: (state: AuthStore) => {
      state.isAuthenticated = false
      state.token = ""
      state.refreshToken = ""
      state.refreshTokenExpiryTime = ""

      localStorage.removeItem("persist:auth")
      localStorage.removeItem("persist:marketplace")
      localStorage.removeItem("persist:root")

      window.location.href = "/login"
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(thunkActions.loginUser.pending, (state) => {
        state.loading = "pending"
      })
      .addCase(thunkActions.loginUser.fulfilled, (state, action) => {
        state.loading = "succeeded"
        state.isAuthenticated = true

        const { data } = action.payload

        state.token = data.token
        state.refreshToken = data.refreshToken
        state.refreshTokenExpiryTime = data.refreshTokenExpiryTime
      })
      .addCase(thunkActions.resetPassword.fulfilled, (state) => {})
  }
})

export const { setIsAuthenticated, refreshToken, logout } = authSlice.actions
export default authSlice.reducer
