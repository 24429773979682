export interface AuthStore {
  isAuthenticated: boolean
  token: string
  refreshToken: string
  refreshTokenExpiryTime: string
  loading: "pending" | "succeeded" | "idle" | "failed"
}

const initialState: AuthStore = {
  isAuthenticated: false,
  loading: "idle",
  token: "",
  refreshToken: "",
  refreshTokenExpiryTime: ""
}

export default initialState
