import { createAsyncThunk } from "@reduxjs/toolkit"
import { UpdateProfile, UpdateUserPassword } from "types/Profile"
import { User } from "types/User"

import handleAPIRequestError, { handleNotificationError } from "utils/handleAPIRequestError"

import * as api from "./api"
import { showNotification } from "./slice"

export const fetchPersonalProfile = createAsyncThunk("app/fetchPersonalProfile", async () => {
  const response = await api.fetchPersonalProfile()
  return response.data
})

export const fetchProfilePermissions = createAsyncThunk("app/fetchProfilePermissions", async () => {
  const response = await api.fetchProfilePermissions()
  return response.data
})

export const updateUserProfile = createAsyncThunk(
  "app/updateUserProfile",
  async (profile: UpdateProfile, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.updateProfile(profile)

      dispatch(showNotification({ status: "success", message: "Profilis atnaujintas" }))
      return response.data as User
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, "Nepavyko atnaujinti profilio")
        })
      )
      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)

export const changeUserPassword = createAsyncThunk(
  "app/changeUserPassword",

  async (data: UpdateUserPassword, { dispatch, rejectWithValue }) => {
    try {
      await api.changeUserPassword(data)

      dispatch(showNotification({ status: "success", message: "Slaptažodis atnaujintas" }))
      return data as UpdateUserPassword
    } catch (error: any) {
      dispatch(
        showNotification({
          status: "error",
          message: handleNotificationError(error.response.data, "Nepavyko pakeisti slaptažodžio")
        })
      )
      return rejectWithValue(handleAPIRequestError(error))
    }
  }
)
