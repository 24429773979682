import http from "http/http"

import { CreateCategory, UpdateCategory } from "types/Category"

export const fetchCategories = () =>
  http.post("/v1/categories/search", {
    pageSize: 9999,
    pageNumber: 1
  })

export const createCategory = ({ name, categoryId }: CreateCategory) =>
  http.post("/v1/categories", { name, categoryId })

export const updateCategory = (data: UpdateCategory) => http.put(`/v1/categories/${data.id}`, data)

export const deleteCategory = (id: string) => http.delete(`/v1/categories/${id}`)
