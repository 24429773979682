import { FC } from "react"

import { Outlet } from "react-router-dom"

import Header from "../Dashboard/Layout/Header"
import MainNavigation from "../Dashboard/Layout/MainNavigation"

const DashboardComposition: FC = () => (
  <div className='flex'>
    <MainNavigation />
    <div className={`flex h-screen flex-1 flex-col overflow-auto bg-white`}>
      <Header />
      <Outlet />
    </div>
  </div>
)

export default DashboardComposition
