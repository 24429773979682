import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import initialState, { SupplierStore } from "./state"
import * as thunkActions from "./apiActions"
import { APIRequestError } from "types/common/ApiRequestError"

export const suppliersSlice = createSlice({
  name: "suppliers",
  initialState,
  reducers: {
    setCurrentPage: (state: SupplierStore, action: PayloadAction<number>) => {
      state.pagination.page = action.payload
    },
    setRowsPerPage: (state: SupplierStore, action: PayloadAction<number>) => {
      state.pagination.rowsPerPage = action.payload
      state.pagination.page = 1
    }
  },
  extraReducers: (builder) => {
    builder
      // Fetch
      .addCase(thunkActions.fetchSuppliers.pending, (state) => {
        state.loading = "pending"
      })
      .addCase(thunkActions.fetchSuppliers.fulfilled, (state, action) => {
        const { data, totalCount } = action.payload
        state.items = data
        state.pagination = { ...state.pagination, totalCount }
        state.loading = "idle"
      })
      // Fetch by id
      .addCase(thunkActions.fetchSupplierById.pending, (state) => {
        state.loading = "pending"
      })
      .addCase(thunkActions.fetchSupplierById.fulfilled, (state) => {
        state.loading = "idle"
      })
      .addCase(thunkActions.fetchAvailableManagers.pending, (state) => {
        state.loading = "pending"
      })
      .addCase(thunkActions.fetchAvailableManagers.fulfilled, (state, action) => {
        state.loading = "idle"
        state.availableManagers = action.payload.data
      })
      // Create
      .addCase(thunkActions.createSupplier.pending, (state) => {
        state.loading = "pending"
      })
      .addCase(thunkActions.createSupplier.fulfilled, (state, action) => {
        state.items.push(action.payload)
        state.loading = "succeeded"
        state.error = {
          errors: {},
          status: 200,
          title: ""
        }
      })
      .addCase(thunkActions.createSupplier.rejected, (state, action) => {
        const { errors, status, title } = action.payload as APIRequestError
        state.loading = "failed"
        state.error = {
          errors,
          status,
          title
        }
      })
      // Update
      .addCase(thunkActions.updateSupplier.pending, (state) => {
        state.loading = "pending"
      })
      .addCase(thunkActions.updateSupplier.fulfilled, (state, action) => {
        const index = state.items.findIndex((item) => item.id === action.payload.id)
        state.items[index] = action.payload
        state.loading = "succeeded"
        state.error = {
          errors: {},
          status: 200,
          title: ""
        }
      })
      .addCase(thunkActions.updateSupplier.rejected, (state, action) => {
        const { errors, status, title } = action.payload as APIRequestError
        state.loading = "failed"
        state.error = {
          errors,
          status,
          title
        }
      })
      // Delete
      .addCase(thunkActions.deleteSupplier.pending, (state) => {
        state.loading = "pending"
      })
      .addCase(thunkActions.deleteSupplier.fulfilled, (state, action) => {
        state.items = state.items.filter((item) => item.id !== action.payload)
        state.pagination.totalCount -= 1
        state.loading = "idle"
      })
  }
})

export const { setCurrentPage, setRowsPerPage } = suppliersSlice.actions
export default suppliersSlice.reducer
