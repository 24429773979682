import { FC, useState } from "react"

import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Collapse from "@mui/material/Collapse"
import { PrivateRoute } from "types/common/Route"
import MainNavigationItem from "./MainNavigationItem"
import { IoSettingsOutline } from "react-icons/io5"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"

interface Props {
  routes: PrivateRoute[]
  expanded: boolean
}

const MainNavigationSettingsGroup: FC<Props> = ({ routes, expanded }) => {
  const [open, setOpen] = useState(false)

  const handleClick = () => setOpen(!open)

  return (
    <>
      <ListItem disablePadding>
        <ListItemButton
          onClick={handleClick}
          dense
          className={[
            `flex h-9 items-center px-4 py-2`,
            !expanded ? "justify-center" : "justify-start"
          ].join(" ")}
        >
          <ListItemIcon className='min-w-min'>
            <IoSettingsOutline className='text-md text-gray-700' />
          </ListItemIcon>
          {expanded && (
            <ListItemText disableTypography className='ml-2 mt-0'>
              <span className='text-[11px] font-bold uppercase text-gray-700'>Valdymas</span>
            </ListItemText>
          )}
          {open ? (
            <ExpandLess className='text-sm text-gray-700' />
          ) : (
            <ExpandMore className='text-sm text-gray-700' />
          )}
        </ListItemButton>
      </ListItem>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' className='bg-zinc-50'>
          {routes.map((route) => (
            <MainNavigationItem key={route.name} route={route} expanded={expanded} />
          ))}
        </List>
      </Collapse>
    </>
  )
}

export default MainNavigationSettingsGroup
