import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import initialState, { OrderStore } from "./state"
import * as thunkActions from "./apiActions"
import { GroupBy, OrderLineItem } from "types/Order"

export const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setCurrentPage: (state: OrderStore, action: PayloadAction<number>) => {
      state.pagination.page = action.payload
    },
    setRowsPerPage: (state: OrderStore, action: PayloadAction<number>) => {
      state.pagination.rowsPerPage = action.payload
      state.pagination.page = 1
    },
    setTableGroupBy: (state, action: PayloadAction<GroupBy>) => {
      state.groupBy = action.payload
      state.rowFocusOn = -1
    },
    setRowFocusOn: (state, action: PayloadAction<number>) => {
      let value = action.payload
      if (value === state.rowFocusOn) {
        value = -1
      }

      state.rowFocusOn = value
    },
    resetPagination: (state: OrderStore) => {
      state.pagination.rowsPerPage = 10
      state.pagination.page = 1
    },

    updateSupplierDeliveryNotes: (state, action: PayloadAction<Record<string, string>>) => {
      if (state.viewOrder) {
        state.viewOrder = {
          ...state.viewOrder,
          deliveryNotesPerSupplier: {
            ...state.viewOrder.deliveryNotesPerSupplier,
            ...action.payload
          }
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunkActions.fetchOrders.pending, (state) => {
        state.loading = "pending"
      })
      .addCase(thunkActions.fetchOrders.fulfilled, (state, action) => {
        const { data, totalCount } = action.payload
        state.items = data
        state.pagination = { ...state.pagination, totalCount }
        state.loading = "idle"
      })
      .addCase(thunkActions.fetchOrder.pending, (state) => {
        state.loading = "pending"
      })
      .addCase(thunkActions.fetchOrder.fulfilled, (state, action) => {
        state.viewOrder = action.payload
        state.loading = "idle"
      })
      .addCase(thunkActions.updateOrderStatus.pending, (state) => {
        state.loading = "pending"
      })
      .addCase(thunkActions.updateOrderStatus.fulfilled, (state, action) => {
        state.viewOrder!.status = action.payload
        state.loading = "idle"
      })
      .addCase(thunkActions.updateDeliveryNotesBySupplier.fulfilled, (state, action) => {
        if (state.viewOrder) {
          state.viewOrder = {
            ...state.viewOrder,
            deliveryNotesPerSupplier: {
              ...state.viewOrder.deliveryNotesPerSupplier,
              ...action.payload
            }
          }
        }
      })
      .addCase(thunkActions.updateOrderDate.fulfilled, (state, action) => {
        state.viewOrder!.deliveryDateOnUtc = action.payload
        state.loading = "idle"
      })
      .addCase(thunkActions.updateLineItemStatus.fulfilled, (state, action) => {
        const { lineItemId, status } = action.payload
        state.viewOrder!.lineItems = state.viewOrder!.lineItems.map((x) =>
          x.id === lineItemId ? { ...x, deliveryStatus: status } : x
        )
        state.loading = "idle"
      })
      .addCase(thunkActions.submitAllItemsAs.pending, (state) => {
        state.loading = "pending"
      })
      .addCase(thunkActions.submitAllItemsAs.fulfilled, (state, action) => {
        const { lineItemIds, status } = action.payload
        state.viewOrder!.lineItems = state.viewOrder!.lineItems.map((x) =>
          lineItemIds.includes(x.id) ? { ...x, deliveryStatus: status } : x
        )
        state.loading = "idle"
      })
      .addCase(thunkActions.updateSupplierDeliveryStatus.fulfilled, (state, action) => {
        const { lineItems, orderStatus, ...data } = action.payload

        if (state.viewOrder) {
          state.viewOrder.lineItems = state.viewOrder!.lineItems.map((x) => {
            const item = lineItems.find((li) => li.id === x.id)

            return item || x
          })

          const hasGoodsMissing = lineItems.filter((x) => x.deliveryStatus === 2).length > 0
          state.viewOrder.supplierDeliverHistory.push({
            supplierId: data.supplierId,
            supplierName: "",
            comment: data.comment,
            status: data.status,
            rating: data.rating,
            goodsMissing: hasGoodsMissing,
            imageUrl: ""
          })
          state.viewOrder.status = orderStatus
        }

        state.loading = "idle"
      })
      .addCase(thunkActions.updateLineItemQuantity.fulfilled, (state, action) => {
        const data = action.payload

        if (state.viewOrder) {
          state.viewOrder.lineItems = state.viewOrder!.lineItems.map((x) => ({
            ...x,
            quantity: x.id === data.lineItemId ? data.quantity : x.quantity
          }))
        }

        state.loading = "idle"
      })
      .addCase(thunkActions.addLineItems.fulfilled, (state, action) => {
        const { lineItems } = action.payload

        if (state.viewOrder) {
          const items = lineItems.map((x) => ({
            id: x.id,
            name: x.name,
            quantity: x.quantity,
            supplierId: x.supplierId,
            supplierName: x.supplierName,
            productId: "",
            deliveryStatus: 0,
            unitPrice: x.unitPrice
          })) as OrderLineItem[]

          state.viewOrder.lineItems = [...state.viewOrder.lineItems, ...items]
        }
      })
      .addCase(thunkActions.deleteLineItem.fulfilled, (state, action) => {
        const data = action.payload

        if (state.viewOrder) {
          state.viewOrder.lineItems = state.viewOrder!.lineItems.filter(
            (x) => x.id !== data.lineItemId
          )
        }

        state.loading = "idle"
      })
  }
})

export const {
  setCurrentPage,
  setRowsPerPage,
  setTableGroupBy,
  setRowFocusOn,
  resetPagination,
  updateSupplierDeliveryNotes
} = orderSlice.actions
export default orderSlice.reducer
