import { FC } from "react"

import CircularProgress from "@mui/material/CircularProgress"

interface Props {
  inline?: boolean
  hideBackground?: boolean
}

const Loader: FC<Props> = ({ inline, hideBackground }) => (
  <div
    className={[
      inline ? "w-full h-full" : "w-screen h-screen",
      "flex items-center justify-center",
      hideBackground ? "" : "bg-zinc-100"
    ].join(" ")}
  >
    <CircularProgress color='primary' thickness={2} size={24} />
  </div>
)

export default Loader
