import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import initialState, { RequestOfferStore } from "./state"
import * as thunkActions from "./apiActions"
import { APIRequestError } from "types/common/ApiRequestError"

export const requestOffersSlice = createSlice({
  name: "requestOffers",
  initialState,
  reducers: {
    setCurrentPage: (state: RequestOfferStore, action: PayloadAction<number>) => {
      state.pagination.page = action.payload
    },
    setRowsPerPage: (state: RequestOfferStore, action: PayloadAction<number>) => {
      state.pagination.rowsPerPage = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      // Fetch all
      .addCase(thunkActions.fetchRequestOffers.pending, (state) => {
        state.loading = "pending"
      })
      .addCase(thunkActions.fetchRequestOffers.fulfilled, (state, action) => {
        const { data, totalCount } = action.payload
        state.items = data
        state.pagination = {
          ...state.pagination,
          totalCount
        }
        state.loading = "idle"
      })
      // Fetch by id
      .addCase(thunkActions.fetchPublicOfferById.pending, (state) => {
        state.loading = "pending"
      })
      .addCase(thunkActions.fetchPublicOfferById.fulfilled, (state) => {
        state.loading = "idle"
      })
      // Update request
      .addCase(thunkActions.updateRequestOffer.pending, (state) => {
        state.loading = "pending"
      })
      .addCase(thunkActions.updateRequestOffer.fulfilled, (state, action) => {
        const index = state.items.findIndex((item) => item.id === action.payload.id)
        state.items[index] = action.payload
        state.loading = "succeeded"
      })
      .addCase(thunkActions.updateRequestOffer.rejected, (state, action) => {
        const { errors, status, title } = action.payload as APIRequestError
        state.loading = "failed"
        state.error = {
          errors,
          status,
          title
        }
      })
  }
})

export const { setCurrentPage, setRowsPerPage } = requestOffersSlice.actions
export default requestOffersSlice.reducer
