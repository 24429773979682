import { Product } from "types/Product"
import { LoadingStatus } from "types/common/LoadingStatus"

import * as paginationConfig from "constants/pagination"
import { APIRequestError } from "types/common/ApiRequestError"

export interface ProductStore {
  items: Product[]
  loading: LoadingStatus

  pagination: paginationConfig.State
  error: APIRequestError
}

const initialState: ProductStore = {
  items: [],
  loading: "idle",

  pagination: paginationConfig.state,
  error: {
    status: 0,
    title: "",
    errors: {}
  }
}

export default initialState
