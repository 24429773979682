import { FC, SyntheticEvent } from "react"

import ClickAwayListener from "@mui/material/ClickAwayListener"
import Fade from "@mui/material/Fade"
import Popper from "@mui/material/Popper"
import Paper from "@mui/material/Paper"
import MenuList from "@mui/material/MenuList"
import MenuItem from "@mui/material/MenuItem"
import Divider from "@mui/material/Divider"

import { IoLogOutOutline, IoPersonCircleOutline } from "react-icons/io5"

interface Props {
  anchorRef: any
  open: boolean
  logout: () => void
  close: (event?: Event | SyntheticEvent) => void
  navigate: (event: Event | SyntheticEvent, to: string) => void
}

const HeaderMenu: FC<Props> = ({ open, anchorRef, logout, close, navigate }) => {
  return (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      placement='bottom-end'
      transition
      container={document.getElementById("popper-portal")}
      sx={{
        zIndex: 10
      }}
    >
      {({ TransitionProps, placement }) => (
        <Fade
          {...TransitionProps}
          style={{
            transformOrigin: placement === "bottom-end" ? "left top" : "left bottom"
          }}
        >
          <Paper elevation={0} className='bg-white shadow-xl'>
            <ClickAwayListener onClickAway={close}>
              <MenuList
                autoFocusItem={open}
                id='composition-menu'
                aria-labelledby='composition-button'
              >
                <MenuItem onClick={(e: any) => navigate(e, "/profile")}>
                  <IoPersonCircleOutline className='mr-2' />
                  <span className='text-sm'>Profilis</span>
                </MenuItem>

                <Divider />
                <MenuItem onClick={logout}>
                  <span className='flex items-center text-red-500'>
                    <IoLogOutOutline className='mr-2' />
                    <span className='text-sm'>Atsijungti</span>
                  </span>
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Fade>
      )}
    </Popper>
  )
}

export default HeaderMenu
