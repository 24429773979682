import { lazy } from "react"

import { PrivateRoute } from "types/common/Route"

import { IoRestaurantOutline } from "react-icons/io5"

const routes: PrivateRoute[] = [
  {
    path: "/canteens",
    name: "canteens",
    title: "Valgyklos",
    component: lazy(() => import("./pages/List")),
    visible: true,
    icon: IoRestaurantOutline,
    canAccess: {
      roles: ["LogisticsManagers"],
      permissions: ["View"]
    },
    group: "settings"
  },
  {
    path: "/canteens/create",
    name: "create-canteen",
    title: "Sukurti valgyklą",
    component: lazy(() => import("./pages/Create")),
    visible: false,
    canAccess: {
      roles: ["LogisticsManagers"],
      permissions: ["View", "Create"]
    },
    group: "settings"
  },
  {
    path: "/canteens/update/:id",
    name: "update-canteen",
    title: "Atnaujinti valgyklą",
    component: lazy(() => import("./pages/Update")),
    visible: false,
    canAccess: {
      roles: ["LogisticsManagers"],
      permissions: ["View", "Update"]
    },
    group: "settings"
  }
]

export default routes
